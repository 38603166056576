import React, { useState, useEffect, useContext } from 'react'
// Contexts
import { useAuthContext } from './AuthContext'
// Services
import { Magento } from '../services/magento'
// Types
import { StoreContextType } from '../types/contexts/StoreContextTypes'
// Utils
import { filterProducts } from '../utils/productHelpers'
import { buildStoreData } from '../utils/storeHelpers'
import { getStoreView, saveStoreView } from '../utils/i18n/stores'
import { initialStoreState } from './initialStates'

const Store = React.createContext<Partial<StoreContextType>>({})

export const useStoreContext = () => useContext(Store)

const StoreProvider = ({ children }) => {
  const [
    { isStoreLoading, isLandingPageLoading, landingPageData, storeData },
    setStoreState,
  ] = useState(initialStoreState)

  const { isAuthLoading, isEventSite, qUser, userType } = useAuthContext()

  Magento.User.setStoreView(
    getStoreView(localStorage, navigator.language).store_code
  )

  useEffect(() => {
    if (isAuthLoading) return
    if (qUser) {
      handleLoadLandingPage(qUser)
      handleLoadStore(qUser)
    } else {
      handleLoadLandingPage()
      handleLoadStore()
    }
  }, [isAuthLoading])

  const handleChangeStore = (storeViewCode: string) => {
    saveStoreView(localStorage, storeViewCode)
    window.location.reload()
  }

  const handleLoadLandingPage = async (qUser = null) => {
    setStoreState(prev => ({
      ...prev,
      isLandingPageLoading: true,
    }))

    await Magento.Store.getLandingPageData()
      .then(({ benefit, type, featuredProducts }) => {
        const filteredFeaturedProducts = filterProducts(
          featuredProducts.items[0].products.items,
          qUser
        )
        return {
          benefit: benefit.items[0],
          type: type.items[0],
          'featured-products': filteredFeaturedProducts,
        }
      })
      .then(data => {
        setStoreState(prev => ({
          ...prev,
          isLandingPageLoading: false,
          landingPageData: { ...data },
        }))
      })
  }

  const handleLoadStore = async (qUser = null) => {
    setStoreState(prev => ({
      ...prev,
      isStoreLoading: true,
    }))

    const productsArr = await Magento.Store.getAllProducts()
      .then(({ products }) => {
       return products.items.map(product => {
         product.categoryNames = product.categories.map(name => name)
         return product
       }) 
      })
      .catch(e => console.log(e))

    setStoreState(prev => ({
      ...prev,
      isStoreLoading: false,
      storeData: buildStoreData(
        productsArr,
        isEventSite,
        userType === 'RETAIL'
      ),
    }))
  }

  return (
    <Store.Provider
      value={{
        isLandingPageLoading,
        isStoreLoading,
        landingPageData,
        storeData,
        handleChangeStore,
      }}
    >
      {children}
    </Store.Provider>
  )
}

export default StoreProvider
