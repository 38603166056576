import React from 'react'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Wrapper, Container, DiscountText } from './OrderDetails.styles'
// Utils
import { getOrderDetailValues } from '../../utils/cartHelpers'

const OrderDetails = ({
  prices,
  shipping,
  showShippingAndTax,
  showWholesaleTotal,
  total_item_count,
  total_quantity,
  wholesale_total,
}) => {
  const {
    prismicData: {
      prismicCartPage: {
        discount: discountText,
        items,
        shipping: shippingPrismicText,
        sub_total,
        tax,
        total,
      },
    },
  } = usePrismic()

  const { getAmounts, getTotalWithShipping } = getOrderDetailValues({
    ...prices,
    shipping,
  })
  const { subTotalText, taxesText, shippingText } = getAmounts()

  const showDiscount = () => {
    if (!prices?.discounts) return null

    return prices?.discounts?.map((discount, i) => {
      const discountLabel = discount?.label ?? discountText[0].text
      const discountValue = `-$${discount?.amount?.value?.toFixed(2) ?? ''}`
      return (
        <Container key={`${discount.label}-${i}`}>
          <DiscountText>{discountLabel}</DiscountText>
          <DiscountText>{discountValue}</DiscountText>
        </Container>
      )
    })
  }

  const showTotal = total_item_count ? total_item_count : total_quantity

  return (
    <Wrapper>
      <Container>
        <span data-qa="subTotalText">{`${sub_total[0].text} (${showTotal} ${items[0].text})`}</span>
        <span data-qa="subTotal">
          ${showWholesaleTotal ? wholesale_total.toFixed(2) : subTotalText}
        </span>
      </Container>
      {showDiscount()}
      {showShippingAndTax ? (
        <>
          <Container>
            <span data-qa="taxText">{tax[0].text}</span>
            <span data-qa="tax">${taxesText}</span>
          </Container>
          <Container>
            <span data-qa="shippingText">{shippingPrismicText[0].text}</span>
            <span data-qa="shipping">{`$${shippingText}`}</span>
          </Container>
        </>
      ) : null}
      <Container total>
        <span data-qa="totalText">{total[0].text}</span>
        <span data-qa="total">
          $
          {showWholesaleTotal
            ? wholesale_total.toFixed(2)
            : getTotalWithShipping()}
        </span>
      </Container>
    </Wrapper>
  )
}

export default OrderDetails
