import { gql } from 'graphql-request'

export const GET_AUTO_SHIP = gql`
  query GetAutoShip($autoShipId: Int!) {
    autoShip(autoShipId: $autoShipId) {
      autoShipId
      dateCreated
      dateModified
      dateStart
      dateLastProcess
      dateNextProcess
      associateId
      autoShipFrequency
      lastChargeAmount
      lastPvAmount
      associatePaymentInformationId
      isActive
      addressId
      address {
        address1
        address2
        city
        state
        zip
        countryCode
      }
      autoShipItems {
        quantity
        isActive
        autoShipItemId
        productName
        sku
        dateCreated
        dateModified
        dS_ItemId
      }
    }
  }
`

export const GET_AUTO_SHIPS = gql`
  query {
    autoShips {
      autoShipId
    }
  }
`

export const ADD_AUTO_SHIP = gql`
  mutation AddAutoShip(
    $dateStart: DateTime!
    $dateNextProcess: DateTime!
    $associateId: Int!
    $address: InputAddressInput!
    $associatePaymentInformationId: Int
    $autoShipItems: [InputAutoShipItemInput!]!
    # NOTE - the shipMethodId will be populated from QS and is still being worked on
    $shipMethodId: Int!
    $isActive: Boolean!
  ) {
    addAutoShip(
      inputAutoShip: {
        dateStart: $dateStart
        dateNextProcess: $dateNextProcess
        associateId: $associateId
        address: $address
        associatePaymentInformationId: $associatePaymentInformationId
        autoShipItems: $autoShipItems
        shipMethodId: $shipMethodId
        isActive: $isActive
      }
    ) {
      autoShipId
      dateCreated
      dateModified
      dateStart
      dateLastProcess
      dateNextProcess
      associateId
      autoShipFrequency
      lastChargeAmount
      lastPvAmount
      associatePaymentInformationId
      isActive
      addressId
      address {
        address1
        address2
        city
        state
        zip
        countryCode
      }
      autoShipItems {
        quantity
        isActive
        autoShipItemId
        productName
        sku
        dateCreated
        dateModified
        dS_ItemId
      }
    }
  }
`

export const UPDATE_AUTO_SHIP_DATE_NEXT_PROCESS = gql`
  mutation UpdateAutoShipDateNextProcess(
    $autoShipId: Int!
    $dateNextProcess: DateTime!
  ) {
    updateAutoShipDateNextProcess(
      autoShipId: $autoShipId
      dateNextProcess: $dateNextProcess
    ) {
      autoShipId
      dateCreated
      dateModified
      dateStart
      dateLastProcess
      dateNextProcess
      associateId
      autoShipFrequency
      lastChargeAmount
      lastPvAmount
      associatePaymentInformationId
      isActive
      addressId
      address {
        address1
        address2
        city
        state
        zip
        countryCode
      }
      autoShipItems {
        quantity
        isActive
        autoShipItemId
        productName
        sku
        dateCreated
        dateModified
        dS_ItemId
      }
    }
  }
`

export const UPDATE_AUTO_SHIP = gql`
  mutation UpdateAutoShip(
    $autoShipId: Int!
    $dateNextProcess: DateTime!
    $address: InputAddressInput
    $associatePaymentInformationId: Int
    $isActive: Boolean
  ) {
    updateAutoShip(
      inputUpdateAutoShip: {
        autoShipId: $autoShipId
        dateNextProcess: $dateNextProcess
        address: $address
        associatePaymentInformationId: $associatePaymentInformationId
        isActive: $isActive
      }
    ) {
      autoShipId
      dateCreated
      dateModified
      dateStart
      dateLastProcess
      dateNextProcess
      associateId
      autoShipFrequency
      lastChargeAmount
      lastPvAmount
      associatePaymentInformationId
      isActive
      addressId
      address {
        address1
        address2
        city
        state
        zip
        countryCode
      }
      autoShipItems {
        quantity
        isActive
        autoShipItemId
        productName
        sku
        dateCreated
        dateModified
        dS_ItemId
      }
    }
  }
`

export const UPDATE_AUTO_SHIP_ITEMS = gql`
  mutation UpdateAutoShipItems(
    $autoShipId: Int!
    $autoShipItems: [InputAutoShipItemInput!]!
  ) {
    updateAutoShipItems(
      inputUpdateAutoShipItems: {
        autoShipId: $autoShipId
        autoShipItems: $autoShipItems
      }
    ) {
      addressId
      associateId
      associatePaymentInformationId
      autoShipFrequency
      autoShipId
      dateCreated
      dateLastProcess
      dateModified
      dateNextProcess
      dateStart
      isActive
      lastChargeAmount
      lastPvAmount
      address {
        address1
        address2
        city
        countryCode
        state
        zip
      }
      autoShipItems {
        autoShipItemId
        dateCreated
        dateModified
        dS_ItemId
        isActive
        productName
        quantity
        sku
      }
    }
  }
`

export const SKIP_AUTO_SHIP_MONTH = gql`
  mutation SkipAutoShipMonth($autoShipId: Int!) {
    skipAutoShipMonth(autoShipId: $autoShipId) {
      autoShipId
      dateCreated
      dateModified
      dateStart
      dateLastProcess
      dateNextProcess
      associateId
      autoShipFrequency
      lastChargeAmount
      lastPvAmount
      associatePaymentInformationId
      isActive
      addressId
      address {
        address1
        address2
        city
        state
        zip
        countryCode
      }
      autoShipItems {
        quantity
        isActive
        autoShipItemId
        productName
        sku
        dateCreated
        dateModified
        dS_ItemId
      }
    }
  }
`
