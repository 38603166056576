import React, { useState } from 'react'
// Components
import { ProductImages } from '..'
import { SignInModal, AddToAutoship } from '../Autoship'
// Context
import { useCartContext } from '../../context/CartContext'
// Hooks
import useAddToCart from '../../hooks/useAddToCart'
import useShowProductPrice from '../../hooks/useShowProductPrice'
// Styles
import {
  AddButton,
  Container,
  MinusButton,
  PlusButton,
  StyledHeader,
  StyledModal,
  StyledModalContent,
  StyledSpan,
} from './AddToModal.styles'

const AddToModal = ({ open, setShowModal, product }) => {
  const { addToAutoShip, autoShipDate } = useCartContext()

  const { image, media_gallery, name, sku, stock_status } = product

  const { count, increment, decrement, isLoading, addToCart } =
    useAddToCart(product)

  const { ProductPricing } = useShowProductPrice(product)

  const [cartOption, setCartOption] = useState('')
  const [showSignIn, setShowSignIn] = useState(false)

  const sortedImages = media_gallery?.sort((a, b) => a.position - b.position)
  const isOutOfStock = stock_status === 'OUT_OF_STOCK'

  const nextAutoshipDate = autoShipDate

  const handleAddProduct = async () => {
    if (cartOption === 'both') {
      await addToCart(count)
      addToAutoShip(product, count)
    } else if (cartOption === 'autoship') {
      addToAutoShip(product, count)
    } else if (cartOption === 'cart') {
      await addToCart(count)
    }
    handleClose()
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <StyledModal
      centered={true}
      closeIcon
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={handleClose}
      open={open}
    >
      {showSignIn && (
        <SignInModal open={showSignIn} setShowModal={setShowSignIn} />
      )}
      <StyledHeader />
      <StyledModalContent>
        <Container justify="space-between" align="center" maxWidth="800px">
          <Container width="50%" justify="flex-end" align="center">
            <ProductImages mediaGallery={sortedImages} image={image} />
          </Container>

          <Container column align="flex-start" width="48%">
            <div style={{ marginBottom: '10px' }}>
              <StyledSpan data-qa={`title-${sku}`} isTitle>
                {name}
              </StyledSpan>
            </div>
            <ProductPricing />
          </Container>
        </Container>

        <Container column align="center" justify="center">
          <StyledSpan data-qa={`count-${count}`} count={count}>
            {count}
          </StyledSpan>
          <Container>
            <MinusButton
              data-qa={`minusButton-${sku}`}
              onClick={() => decrement()}
            >
              <span style={{ fontSize: '2em' }}>-</span>
            </MinusButton>
            <PlusButton
              data-qa={`plusButton-${sku}`}
              onClick={() => increment()}
            >
              <span style={{ fontSize: '2em' }}>+</span>
            </PlusButton>
          </Container>
        </Container>

        <Container margin="1em">
          <AddToAutoship
            cartOption={cartOption}
            setCartOption={setCartOption}
            nextAutoshipDate={nextAutoshipDate}
          />
        </Container>

        <Container justify="center">
          <AddButton
            content={isOutOfStock ? 'Out of Stock' : 'Add'}
            data-qa={`add-${sku}`}
            disabled={isLoading || isOutOfStock || !cartOption}
            loading={isLoading}
            onClick={handleAddProduct}
          />
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}

export default AddToModal
