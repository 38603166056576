import { prodFirebase, stagingFirebase } from './config/firebase'
import {
  productionSentry,
  stagingSentry,
  developmentSentry,
  localSentry,
} from './config/sentry'

export const isBrowser = typeof window !== 'undefined'

const environmentHostMap = {
  // Main production site
  'shopq.qsciences.com': 'production',
  'shopq.azurewebsites.net': 'production',
  'shopq-88634.web.app': 'production',
  'qsciences.com': 'production',
  'shopq-88634-preproduction.web.app': 'preproduction',
  // Staging
  'shopq-staging.qsciences.com': 'staging',
  'shopq-88634-staging.web.app': 'staging',
  // Event sites
  'insynq.qsciences.com': 'eventProduction',
  'unite.qsciences.com': 'eventProduction',
  'shopq-88634-unite.web.app': 'eventProduction',
  // VIP sites
  'vip.qsciences.com': 'vipProduction',
  'shopq-88634-vip.web.app': 'vipProduction',
  // Owners Tour
  'ownerstour.qsciences.com': 'ownerstourProduction',
  'shopq-88634-ownerstour.web.app': 'ownerstourProduction',
  // Testing sites
  'shopq-88634-testenv.web.app': 'staging',
  // Event/VIP Testing sites
  'shopq-88634-testing.web.app': 'vipStaging',
  'shopq-88634-ignite-staging.web.app': 'eventStaging',
  'ignite-staging.qsciences.com': 'eventStaging',
  // Development
  'shopq-dev.qsciences.com': 'staging',
  'shopq-88634-dev.web.app': 'staging',
  'localhost:8000': 'staging',
  // 'localhost:8000': 'vipStaging',
  // 'localhost:8000': 'eventStaging',
  // 'localhost:8000': 'eventProduction',
}

const environments = {
  production: {
    QSERVICES_API_URL: 'https://qservicesapi.azurewebsites.net/graphql',
    MAGENTO_API_URL: 'https://mcprod.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=production',
    NEXIO_URL: 'https://api.nexiopay.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: prodFirebase,
    SENTRY: productionSentry,
    ENV: 'production',
    GOOGLE_ANALYTICS_ID: 'G-JQQ2426NQ3',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  preproduction: {
    QSERVICES_API_URL: 'https://qservicesapi.azurewebsites.net/graphql',
    MAGENTO_API_URL: 'https://mcstaging2.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=preproduction',
    NEXIO_URL: 'https://api.nexiopay.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: prodFirebase,
    SENTRY: productionSentry,
    ENV: 'preproduction',
    GOOGLE_ANALYTICS_ID: 'G-JQQ2426NQ3',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  eventProduction: {
    QSERVICES_API_URL: 'https://qservicesapi.azurewebsites.net/graphql',
    MAGENTO_API_URL: 'https://mcprod.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=production',
    NEXIO_URL: 'https://api.nexiopay.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: prodFirebase,
    SENTRY: productionSentry,
    ENV: 'production',
    GOOGLE_ANALYTICS_ID: 'G-SDVBZEL01B',
    IS_EVENT_SITE: true,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  vipProduction: {
    QSERVICES_API_URL: 'https://qservicesapi.azurewebsites.net/graphql',
    MAGENTO_API_URL: 'https://mcprod.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=production',
    NEXIO_URL: 'https://api.nexiopay.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: prodFirebase,
    SENTRY: productionSentry,
    ENV: 'production',
    GOOGLE_ANALYTICS_ID: 'G-SDVBZEL01B',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: true,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  ownerstourProduction: {
    QSERVICES_API_URL: 'https://qservicesapi.azurewebsites.net/graphql',
    MAGENTO_API_URL: 'https://mcprod.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=production',
    NEXIO_URL: 'https://api.nexiopay.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: prodFirebase,
    SENTRY: productionSentry,
    ENV: 'production',
    GOOGLE_ANALYTICS_ID: 'G-SDVBZEL01B',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: true,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  eventStaging: {
    QSERVICES_API_URL: 'https://qservices-staging.qsciences.com/graphql',
    MAGENTO_API_URL: 'https://mcstaging.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=staging',
    NEXIO_URL: 'https://api.nexiopaysandbox.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: stagingFirebase,
    SENTRY: stagingSentry,
    ENV: 'staging',
    GOOGLE_ANALYTICS_ID: 'G-8V6XJ2G77B',
    IS_EVENT_SITE: true,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  vipStaging: {
    QSERVICES_API_URL: 'https://qservices-staging.qsciences.com/graphql',
    MAGENTO_API_URL: 'https://mcstaging.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=staging',
    NEXIO_URL: 'https://api.nexiopaysandbox.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: stagingFirebase,
    SENTRY: stagingSentry,
    ENV: 'staging',
    GOOGLE_ANALYTICS_ID: 'G-8V6XJ2G77B',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: true,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  staging: {
    QSERVICES_API_URL: 'https://qservices-staging.qsciences.com/graphql',
    MAGENTO_API_URL: 'https://mcstaging.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=staging',
    NEXIO_URL: 'https://api.nexiopaysandbox.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: stagingFirebase,
    SENTRY: stagingSentry,
    ENV: 'staging',
    GOOGLE_ANALYTICS_ID: 'G-8V6XJ2G77B',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  development: {
    QSERVICES_API_URL: 'https://qservices-dev.qsciences.com/graphql',
    MAGENTO_API_URL: 'https://mcstaging.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=development',
    NEXIO_URL: 'https://api.nexiopaysandbox.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: stagingFirebase,
    SENTRY: developmentSentry,
    ENV: 'development',
    GOOGLE_ANALYTICS_ID: 'G-8V6XJ2G77B',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
  local: {
    QSERVICES_API_URL: 'https://qservices-dev.qsciences.com/graphql',
    MAGENTO_API_URL: 'https://mcstaging.qsciences.com/graphql',
    AZURE_CUSTOMERGROUP_FUNCTION:
      'https://qsazurefunctions.azurewebsites.net/api/updatemagentocustomergroup?code=PTaXE1yO9LuZdDgMKb0DjwcDnPtpG3pNcghts3ZDVVKRkvh%2FL8gsKQ%3D%3D&environment=staging',
    NEXIO_URL: 'https://api.nexiopaysandbox.com',
    GOOGLE_MAP_API_KEY: 'AIzaSyC2dPnQc-JjKgW1E8a-QCRdFNFZ4rTuL8k',
    FIREBASE: stagingFirebase,
    SENTRY: localSentry,
    ENV: 'local',
    GOOGLE_ANALYTICS_ID: 'G-8V6XJ2G77B',
    IS_EVENT_SITE: false,
    IS_OWNERS_TOUR: false,
    IS_VIP_SITE: false,
    SLACK_WEBHOOK_URL:
      'https://hooks.slack.com/services/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',
  },
}

/**
 * Determines the environment based on the url of the site. If no match, returns local
 */
const getEnvironment = (host = window.location.host) => {
  const currentEnv = environmentHostMap[host] || 'staging'
  return {
    isBrowser,
    ...environments[currentEnv],
  }
}

/**
 * The same as getEnvironment, but safe for builds where the window object does not exist
 */
const getEnvironmentBuildSafe = () => {
  if (isBrowser) return getEnvironment()
  else return {}
}

export default getEnvironmentBuildSafe()
