import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// Components
import { Modal, Radio } from 'semantic-ui-react'
import { Primary } from '../../components'
import {
  StyledModal,
  StyledLogo,
  StyledHeader,
  Container,
  StyledText,
  StyledTextSmall,
  StyledCheck,
  Gap,
  Divider,
} from './PickYourPerksAmbEnrModal.styles'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
// Services
import { Magento } from '../../services/magento'
// Utils
import { enrollSkus } from '../../utils/constants'

const PickYourPerksAmbEnrModal = ({ open, onClose }) => {
  const { qUser, qUserUpline } = useAuthContext()
  const {
    ambEnrollProducts,
    setAmbEnrollProducts,
    selectedAmbEnrollSku,
    setSelectedAmbEnrollSku,
  } = useCartContext()

  const { ambPremiumSku, ambBasicSku } = enrollSkus
  const { premium, basic } = ambEnrollProducts

  const getEnrollmentFees = async () => {
    const ambPremium = await Magento.Store.getSingleProductBySku({
      sku: ambPremiumSku,
    })
    const ambBasic = await Magento.Store.getSingleProductBySku({
      sku: ambBasicSku,
    })
    setAmbEnrollProducts({
      premium: ambPremium,
      basic: ambBasic,
    })
  }

  useEffect(() => {
    getEnrollmentFees()
    setSelectedAmbEnrollSku(ambPremiumSku)
  }, [enrollSkus])

  const skuPriceMap = {
    [ambPremiumSku]: premium,
    [ambBasicSku]: basic,
  }

  const totalCost =
    skuPriceMap[selectedAmbEnrollSku]?.products?.items[0]?.price?.regularPrice
      ?.amount?.value

  const onHandleSkuChange = name => setSelectedAmbEnrollSku(name)

  return (
    <StyledModal
      open={open}
      centered={true}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Container direction="column">
        <StyledLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        <Gap />
        <StyledTextSmall weight={900}>Ambassador Enrollment</StyledTextSmall>
        <Gap />
        {qUserUpline?.displayName.length > 0 && (
          <StyledTextSmall>{`(referred by ${qUserUpline?.displayName})`}</StyledTextSmall>
        )}
        <Gap height="16px" />
        <StyledText>
          {`Welcome ${qUser?.legalFirstName ?? ''} ${
            qUser?.legalLastName ?? ''
          }! It's time to enroll as a Q Sciences Ambassador.`}
        </StyledText>
        <Gap height="16px" />
        <StyledHeader>Pick Your Perks!</StyledHeader>
      </Container>
      <Modal.Content>
        <Container direction="column" padding="0">
          <Container justify="flex-start">
            <Radio
              style={{ marginRight: 12 }}
              checked={selectedAmbEnrollSku === ambBasicSku}
              onChange={() => onHandleSkuChange(ambBasicSku)}
            />
            <StyledTextSmall
              weight={900}
              align="left"
              onClick={() => onHandleSkuChange(ambBasicSku)}
              style={{ cursor: 'pointer' }}
            >
              Basic Ambassador
            </StyledTextSmall>
          </Container>
          <Gap />

          <Container direction="column" style={{ paddingLeft: 16 }}>
            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                Full Access to the Ambassador Resources Q (ARQ) app.
              </StyledTextSmall>
            </Container>
            <Gap />

            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                Full Access to the MyQFit app.
              </StyledTextSmall>
            </Container>
          </Container>
        </Container>
        <Gap height="12px" />

        <Container direction="column" padding="0">
          <Container justify="flex-start">
            <Radio
              style={{ marginRight: 12 }}
              checked={selectedAmbEnrollSku === ambPremiumSku}
              onChange={() => onHandleSkuChange(ambPremiumSku)}
            />
            <StyledTextSmall
              weight={900}
              align="left"
              onClick={() => onHandleSkuChange(ambPremiumSku)}
              style={{ cursor: 'pointer' }}
            >
              Premium Ambassador
            </StyledTextSmall>
          </Container>
          <Gap />
          <Container direction="column" style={{ paddingLeft: 16 }}>
            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                Everything in basic.
              </StyledTextSmall>
            </Container>
            <Gap />

            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall weight={900} align="left">
                Free shipping on Subscription orders for a full year - up to
                $130 in savings!
              </StyledTextSmall>
            </Container>
          </Container>
          <Gap height="12px" />
          <StyledText weight={900}>{`Total: $${totalCost}`}</StyledText>
          <Gap height="24px" />
          <Divider />
          <Gap height="24px" />
          <Primary onClick={onClose} content={`${'continue'.toUpperCase()}`} />
        </Container>
      </Modal.Content>
    </StyledModal>
  )
}

PickYourPerksAmbEnrModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PickYourPerksAmbEnrModal
