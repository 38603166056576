import { ProductType } from '../types/ProductTypes'

const buildEventCategories = () => ({
  type: {
    subCategories: {
      nutritionals: { products: [] },
      collagen: { products: [] },
      'hemp-terpenes': { products: [] },
      drinks: { products: [] },
      myqfit: { products: [] },
      swag: { products: [] },
      'old-swag': { products: [] },
    },
    products: [],
  },
  benefit: {
    subCategories: {},
    products: [],
  },
  autoShipProducts: { products: [] },
})

const buildCategories = (isRetail: boolean) => ({
  type: {
    subCategories: {
      kids: { products: [] },
      'oral-sprays': { products: [] },
      nutritionals: { products: [] },
      hemp: { products: [] },
      topicals: { products: [] },
      'drink-mixes': { products: [] },
      collagen: { products: [] },
      protein: { products: [] },
      terpenes: { products: [] },
      swag: { products: [] },
      promotions: { products: [] },
      packs: { products: [] }
    },
    products: [],
  },
  benefit: {
    subCategories: {},
    products: [],
  },
  autoShipProducts: { products: [] },
})

export const buildStoreData = (
  productsArr: ProductType[],
  isEventSite: boolean,
  isRetail?: boolean
) => {
  let categories = {}

  if (isEventSite) {
    categories = buildEventCategories()
  } else {
    categories = buildCategories(isRetail)
  }

  productsArr?.forEach(product => {
    // build autoship products list
    if (product.autoship) categories?.autoShipProducts.products.push(product)

    product?.categories?.forEach((category, i) => {
      if (!!category.breadcrumbs) {
        const path = category.url_path.split('/')
        const parent = path[0]
        const child = path[1]

        if (parent === 'type') {
          if (child in categories[parent].subCategories) {
            categories[parent].subCategories[child].products.push(product)
            categories[parent].subCategories[child] = {
              ...categories[parent].subCategories[child],
              ...category,
            }
          } else {
            return
          }
        } else {
          if (child in categories[parent].subCategories) {
            categories[parent].subCategories[child].products.push(product)
          } else {
            categories[parent].subCategories[child] = {
              ...category,
              products: [product],
            }
          }
        }
      } else {
        const catKey = category.url_key

        if (catKey in categories) {
          categories[catKey].products.push(product)
          categories[catKey] = {
            ...categories[catKey],
            ...category,
          }
        } else {
          categories[catKey] = {
            ...category,
            products: [product],
            subCategories: [],
          }
        }
      }
    })
  })

  return categories
}
