import toast from 'react-hot-toast'
import { Logger } from './logger'
// Services & Utils
import { parseGraphqlError } from './graphqlErrorParser'

export const OUT_OF_STOCK = 'OUT_OF_STOCK'

export const handleOutOfStock = e => {
  const graphqlResponse = parseGraphqlError(e.toString())
  const { errors, data } = graphqlResponse
  if (!errors) {
    Logger.log(e)
    return
  }

  const outOfStock = errors.some(
    ({ message }) => message === 'Some of the products are out of stock.'
  )
  if (outOfStock) return data
}

export const displayOutOfStockError = ({ items }) => {
  const outOfStockItems = items
    .filter(({ product }) => product.stock_status === OUT_OF_STOCK)
    .map(({ product }) => product.name)
  const outOfStockItemTitles = outOfStockItems.join(',')
  const message = `An item in your cart is out of stock: ${outOfStockItemTitles}`
  toast.error(message)
}

/**
 * If a product is part of the Exclude PC category, PC users should not be able to see it
 * If a product is part of the Retail category, Retail users should not be able to see it
 * If a product is part of the Packs category, Retail users should not be able to see it
 */
export const filterProducts = (products, qUser, isPcInCart=false, isAmbInCart=false) => {
  if (!products) return []
  const associateType = qUser?.associateType ?? 'NOT LOGGED IN'
  products = products.map(product => {
    product.categoryNames = product.categories.map(({name}) => name)
    return product
  })
  if (associateType === 'AMBASSADOR' || isAmbInCart) {
    return products
  } else if (associateType === 'PREFERRED' || isPcInCart) {
    return products.filter(
        ({categoryNames}) => !categoryNames.includes('Exclude PC')
    )
  } else {
    return products.filter(
        ({categoryNames}) =>
            !categoryNames.some(
                category => category === 'Exclude Retail' || category === 'Packs'
            )
    ) // if you're not logged in, or not retail, you can't see packs   
  }
}