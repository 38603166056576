import React from 'react'
import { Button, Input, Label } from 'semantic-ui-react'
// Components
import { CartProductCard, SearchMenu } from '../'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container } from '../../styles/common'
import { AS } from './styled'

type ManageProps = {
  children?: React.ReactElement
}

const Details = ({
  pvTotalText = '',
  reward = 0,
  rewardPointsEarnedText = '',
  showPv,
  total = 0,
  totalPv = 0,
  totalText = '',
}) => (
  <>
    <AS.DetailsRow>
      <span>{totalText}</span>
      <span>${total.toFixed(2)}</span>
    </AS.DetailsRow>
    {showPv ? (
      <AS.DetailsRow>
        <span>{pvTotalText}</span>
        <span>{totalPv}</span>
      </AS.DetailsRow>
    ) : null}
    {/* <AS.DetailsRow>
      <span>{rewardPointsEarnedText}</span>
      <span>{reward || 'TBD'}</span>
    </AS.DetailsRow> */}
  </>
)

const Manage = ({ children }: ManageProps) => {
  const {
    prismicData: {
      prismicAutoshipPage: {
        next_autoship,
        pv_total: pvTotalText,
        reward_points_earned,
        total: totalText,
      },
    },
  } = usePrismic()
  const {
    autoShipItems,
    autoShipDate,
    handleSetAutoShipState,
    addToAutoShip,
    isAmbOfferInCart,
  } = useCartContext()
  const { userType } = useAuthContext()

  const getTotal = arr =>
    arr?.reduce(({ total = 0, totalPv = 0 }, { product, quantity }) => {
      const { wholesale_price, pv } = product
      const price = +wholesale_price
      return {
        total: total + price * quantity,
        totalPv: totalPv + pv * quantity,
      }
    }, 0)

  const handleClearDate = () => handleSetAutoShipState({ autoShipDate: '' })

  return (
    <>
      <AS.Wrapper>
        <Input value={autoShipDate} onChange={handleClearDate} action>
          <Label basic>{next_autoship[0].text}</Label>
          <input />
          <Button basic icon="calendar alternate" onClick={handleClearDate} />
        </Input>
        <SearchMenu type="add" callback={addToAutoShip} />
      </AS.Wrapper>
      <Container padded>
        {autoShipItems?.map((item, i) => {
          return (
            <div key={`${item?.autoShipItemId ?? item.sku}-${i}`}>
              <CartProductCard item={item} />
            </div>
          )
        })}
        <Container padding="20px 0 0 0">
          <Details
            {...getTotal(autoShipItems)}
            totalText={totalText[0].text}
            pvTotalText={pvTotalText[0].text}
            rewardPointsEarnedText={reward_points_earned[0].text}
            showPv={userType === 'AMBASSADOR' || isAmbOfferInCart}
          />
        </Container>
      </Container>
      {children}
    </>
  )
}

Manage.displayName = 'AutoshipManage'

export default Manage
