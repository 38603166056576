import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { useStoreContext } from '../../context/StoreContext'
// Styles
import {
  ImageWrapper,
  SearchItemInfo,
  SearchItemWrapper,
  StyledImage,
  StyledSearch,
  StyledSearchAdd,
  StyledSpan,
  TitleContainer,
} from './SearchMenu.styles'
// Types
import { ProductType } from '../../types/ProductTypes'

type InitialState = {
  isLoading: boolean
  results: ProductType[]
  value: string
}

const initialState = {
  isLoading: false,
  results: [],
  value: '',
}

const resultRenderer = ({
  image: { url },
  show_pv,
  price,
  pv,
  sku,
  title,
  uid,
  wholesale_price,
}) => {
  return (
    <SearchItemWrapper key={uid}>
      <ImageWrapper data-qa={`searchImage-${sku}`}>
        <StyledImage src={url} />
      </ImageWrapper>
      <SearchItemInfo>
        <TitleContainer data-qa={`searchTitle-${sku}`}>
          <StyledSpan isTitle>{title}</StyledSpan>
        </TitleContainer>
        <div>
          <StyledSpan
            data-qa={`searchPrice-${sku}`}
            price
          >{`$${price}`}</StyledSpan>
          {show_pv === 'true' ? (
            <StyledSpan data-qa={`searchPV-${sku}`} pv>
              PV: {`${pv}`}
            </StyledSpan>
          ) : null}
        </div>
      </SearchItemInfo>
    </SearchItemWrapper>
  )
}

export default function SearchMenu({ type, callback }) {
  const [{ isLoading, results, value }, setState] =
    useState<InitialState>(initialState)
  const [data, setData] = useState(null)

  const { userType } = useAuthContext()
  const { isAmbOfferInCart } = useCartContext()
  const { isStoreLoading, storeData } = useStoreContext()

  useEffect(() => {
    if (isStoreLoading) return
    const allProducts = storeData['all-products'].products
    const autoShipItems = storeData.autoShipProducts.products

    const handleMakeData = products =>
      products.map(
        ({
          autoship,
          ds_itemid,
          image,
          name,
          price_range,
          pv,
          sku,
          uid,
          url_key,
          wholesale_price,
        }) => {
          const finalPrice = price_range?.maximum_price?.final_price?.value
          const shouldShowPv = userType === 'AMBASSADOR' || isAmbOfferInCart
          return {
            autoship,
            ds_itemid,
            image,
            show_pv: shouldShowPv ? 'true' : 'false',
            name,
            price_range,
            price: finalPrice ? finalPrice.toFixed(2) : '-',
            pv,
            sku,
            title: name,
            uid,
            url_key,
            wholesale_price,
          }
        }
      )

    setData(
      type === 'add'
        ? handleMakeData(autoShipItems)
        : handleMakeData(allProducts)
    )
  }, [isStoreLoading])

  const handleResultSelect = (e, { result }) => {
    if (type === 'link') {
      callback()
      navigate(`/products/${result.url_key}`)
    } else {
      callback(result, 1)
      setState(initialState)
    }
  }

  const handleSearchChange = (e, { value }) => {
    setState(prev => ({ ...prev, isLoading: true, value }))

    if (value.length < 1) {
      setState(prev => ({ ...prev, isLoading: false }))
      return
    }

    // TODO - Add debounce here
    setTimeout(() => {
      const results = data.filter(product =>
        product.title.toLowerCase().includes(value.toLowerCase())
      )
      setState(prev => ({ ...prev, isLoading: false, results }))
    }, 500)
  }

  return type === 'link' ? (
    <StyledSearch
      autoFocus
      data-qa="searchInputLink"
      fluid
      loading={isLoading}
      minCharacters={2}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={value}
    />
  ) : (
    <StyledSearchAdd
      className="search-add"
      data-qa="searchInputAdd"
      fluid
      loading={isLoading}
      minCharacters={2}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      placeholder="Click HERE to quickly add products!"
      resultRenderer={resultRenderer}
      results={results}
      value={value}
    />
  )
}
