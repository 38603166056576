/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState, useEffect } from 'react'
import { Modal, Header, Radio } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import { Primary as SubmitButton, Basic as CancelButton } from '../'
// Context
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
// Hooks
import useShowError from '../../hooks/useShowError'
// Styles
import {
  Container,
  ErrorMsg,
  FieldWrapper,
  Logo,
  StyledForm,
  StyledInput,
  StyledModal,
} from './Verification.styles'
import { StyledCheckboxLabel } from '../../styles/RegisterPage.styles'
// Utils
import { existingCustomer, newCustomer } from '../../utils/validations'

const ExistingQ = ({ isOpen, getUserCallback, cancel }) => {
  const {
    prismicData: {
      prismicExistingQModal: {
        are_you_an_existing_q_science_customer,
        cancel: cancelText,
        continue: continueText,
        first_name,
        last_name,
        logo,
        no: noText,
        placeholder,
        yes: yesText,
      },
    },
  } = usePrismic()

  const [open, setOpen] = useState(isOpen)
  const [isQMember, setIsQMember] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleAddNewUser,
    handleUserLogout,
    manageReferral: { referralData, isReferral },
  } = useAuthContext()

  const [error, setError, showError] = useShowError()

  useEffect(() => {
    if (isReferral) {
      setIsQMember(false)
    }
  }, [isReferral])

  const handleSetResolvers = () => ({
    resolver: isQMember
      ? yupResolver(existingCustomer)
      : yupResolver(newCustomer),
    defaultValues: {
      firstName: referralData?.firstname ?? '',
      lastName: referralData?.lastname ?? '',
    },
  })

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(handleSetResolvers())

  const resetAndShowError = message => {
    reset()
    setError(message)
  }

  const onSubmit = data => {
    getUserCallback(data.customer, resetAndShowError)
  }

  const handleClose = () => {
    cancel()
    setOpen(false)
  }

  const handleCheckbox = () => {
    reset()
    setIsQMember(!isQMember)
  }

  const handleIsNewUser = async userInfo => {
    setIsLoading(true)
    await handleAddNewUser(userInfo).then(() => setIsLoading(false))
  }

  const handleCancel = () => {
    handleUserLogout()
    handleClose()
  }

  // TODO - Add loading state to button for non-existing customer
  return (
    <StyledModal
      centered={false}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      dimmer="blurring"
      onClose={handleClose}
      open={open}
    >
      <Container direction="column">
        <Logo src={logo.url} />
        <Modal.Description>
          <Header textAlign="center">
            {are_you_an_existing_q_science_customer[0].text}
          </Header>
        </Modal.Description>
        <Container
          direction="row"
          justify="space-evenly"
          maxWidth="400px"
          padding="20px 0 5px 0"
        >
          <Radio
            checked={isQMember}
            label={<StyledCheckboxLabel>{yesText[0].text}</StyledCheckboxLabel>}
            name="q-member"
            onChange={handleCheckbox}
            style={{ marginTop: '5px' }}
          />

          <Radio
            checked={!isQMember}
            label={<StyledCheckboxLabel>{noText[0].text}</StyledCheckboxLabel>}
            name="new-user"
            onChange={handleCheckbox}
            style={{ marginTop: '5px' }}
          />
        </Container>
        {error && showError()}
        <Container direction="column">
          {isQMember ? (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <ErrorMsg>{errors.customer?.message}</ErrorMsg>
              <StyledInput
                {...register('customer')}
                placeholder={placeholder[0].text}
                onFocus={() => setError('')}
                autoFocus
              />
              <SubmitButton type="submit">{continueText[0].text}</SubmitButton>
            </StyledForm>
          ) : (
            <StyledForm onSubmit={handleSubmit(handleIsNewUser)}>
              <FieldWrapper>
                <ErrorMsg>{errors.firstName?.message}</ErrorMsg>
                <StyledInput
                  {...register('firstName')}
                  placeholder={first_name[0].text}
                  onFocus={() => setError('')}
                  autoFocus
                  margin="0"
                />
              </FieldWrapper>

              <FieldWrapper>
                <ErrorMsg>{errors.lastName?.message}</ErrorMsg>
                <StyledInput
                  {...register('lastName')}
                  placeholder={last_name[0].text}
                  onFocus={() => setError('')}
                  margin="0.5rem 0 0"
                />
              </FieldWrapper>
              <SubmitButton loading={isLoading}>
                {continueText[0].text}
              </SubmitButton>
            </StyledForm>
          )}
          {/* <CancelButton style={{ marginTop: '0' }} onClick={handleCancel}>
            {cancelText[0].text}
          </CancelButton> */}
        </Container>
      </Container>
    </StyledModal>
  )
}

export default ExistingQ
