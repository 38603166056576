import React from 'react'
import { ButtonProps } from 'semantic-ui-react'
// Styles
import {
  AddButton,
  AddSubContainer,
  BasicPrimaryButton,
  Count,
  PrimaryButton,
  SubtractButton,
  WarningButton,
} from './Buttons.styles'

export const Primary = ({ children, ...rest }: ButtonProps) => (
  <PrimaryButton data-qa={`primaryButton-${children}`} {...rest}>
    {children}
  </PrimaryButton>
)

export const Basic = ({ children, ...rest }) => (
  <BasicPrimaryButton data-qa={`basicButton-${children}`} {...rest}>
    {children}
  </BasicPrimaryButton>
)

export const Warning = ({ children, ...rest }) => (
  <WarningButton data-qa={`warningButton-${children}`} {...rest}>
    {children}
  </WarningButton>
)

export const AddSubComponent = ({ add, sub, count, children, ...rest }) => (
  <AddSubContainer>
    <SubtractButton data-qa="subButton" onClick={sub} {...rest}>
      -
    </SubtractButton>
    <Count data-qa="addSubCount">{count}</Count>
    <AddButton data-qa="addButton" onClick={add} {...rest}>
      +
    </AddButton>
  </AddSubContainer>
)
