import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Modal } from 'semantic-ui-react'
// Components
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'

import { Primary as SendButton, Warning as CancelButton } from '..'
// Styles
import {
  StyledForm,
  StyledInput,
  StyledLink,
  SuccessMsg,
  ErrorMsg,
  Container,
  ModalContent,
} from './ForgotPassword.styles'
// Utils
import { forgotPasswordSchema } from '../../utils/validations'
// Hooks
import useShowError from '../../hooks/useShowError'

const defaultState = {
  open: false,
  success: false,
  loading: false,
}

const ForgotPasswordModal = ({ resetLogin }) => {
  const { prismicData } = usePrismic()
  const {
    cancel,
    close,
    email_address,
    email_sent,
    enter_email_address,
    forgot_your_password,
    reset_password,
  } = prismicData.prismicForgotPasswordPage

  const [{ open, success, loading }, setState] = useState(defaultState)

  const { resetPassword } = useAuthContext()
  const [error, setError, showError] = useShowError()

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) })

  const onSubmit = data => {
    if (data.resetEmail === '') return
    setState(prevState => ({ ...prevState, loading: true }))
    resetPassword(data.resetEmail)
      .then(() => {
        setState(prevState => ({ ...prevState, loading: false, success: true }))
      })
      .catch(err => {
        setState(prevState => ({
          ...prevState,
          loading: false,
          success: false,
        }))
        setError(err.message)
      })
  }

  const handleClose = () => {
    reset()
    resetLogin()
    setState(defaultState)
  }

  return (
    <Modal
      open={open}
      centered={false}
      closeOnDimmerClick={false}
      closeOnEscape
      onClose={handleClose}
      onOpen={() => setState(prevState => ({ ...prevState, open: true }))}
      trigger={
        <StyledLink data-qa="signInResetPassword">
          {reset_password[0].text}
        </StyledLink>
      }
    >
      <Header style={{ margin: '1em' }} textAlign="center" size="huge">
        {reset_password[0].text}
      </Header>
      <ModalContent>
        <Header textAlign="center" color="grey">
          {enter_email_address[0].text}
        </Header>
        {error && showError()}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <ErrorMsg>{errors.resetEmail?.message}</ErrorMsg>
            <StyledInput
              {...register('resetEmail')}
              placeholder={email_address[0].text}
              data-qa="emailInput"
            />
          </Container>
          {success && !loading ? (
            <SuccessMsg>{email_sent[0].text}</SuccessMsg>
          ) : (
            <SendButton type="submit" loading={loading}>
              {reset_password[0].text}
            </SendButton>
          )}
          <CancelButton onClick={handleClose}>
            {success ? close[0].text : cancel[0].text}
          </CancelButton>
        </StyledForm>
      </ModalContent>
    </Modal>
  )
}

export default ForgotPasswordModal
