import { Modal, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import check from '../../svg/CheckCircle.svg'

export const StyledModal = styled(Modal)`
  width: 80%;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px;
  }

  &&& {
    &.ui.modal > .content {
      padding: 40px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    i.close {
      top: 5px;
      left: 5px;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.black};
      border-radius: 50%;
      opacity: 1;
      width: 24px;
      height: 24px;
      padding-top: 5px;
      font-size: 15px;
      font-weight: 400;
    }
  }
`
export const StyledModalContent = styled(Modal.Content)`
  padding: 0px;
`

export const StyledHeader = styled.div`
  width: 100%;
  padding: 4px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.metallicSeaweed};

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px;
  }
`

interface ContainerProps {
  direction: 'column' | 'row'
  justify: string
  align: string
  padding: string
  maxWidth: string
  textAlign: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  padding: ${props => props.padding};
`

interface StyledTextProps {
  align: string
}

export const StyledText = styled.span<Partial<StyledTextProps>>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  text-align: ${props => props.align || 'center'};

  @media ${({ theme }) => theme.device.sm} {
    font-size: 18px;
  }
`

export const StyledTextSmall = styled.span<Partial<StyledTextProps>>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.nero};
  text-align: ${props => props.align || 'center'};
  margin: 10px 0;
`

export const StyledLogo = styled(Image)`
  /* width: 90%; */
  width: 275px;
  height: auto;
`

export const StyledCheck = styled(Image).attrs({
  src: check,
})`
  &&& {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`
