import React, { useEffect, useState, useReducer } from 'react'
// Components
import { ProductCard, ProductImages, PageHeader } from '..'
import { AddToAutoship, SignInModal } from '../Autoship'
import ConfigProduct from './ConfigurableProduct'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Hooks
import useAddToCart from '../../hooks/useAddToCart'
import useShowProductPrice from '../../hooks/useShowProductPrice'
// Styles
import {
  AddButton,
  CardsContainer,
  Container,
  DescContainer,
  MinusButton,
  PlusButton,
  StyledSpan,
  TabItem,
  TabItemContent,
  TabWrapper,
  Wrapper,
} from './ProductSummary.styles'

// TODO - Find out what is supposed to happen on click of more details. Show a div? Go to product?
// const RelatedProduct = ({ name }) => (
//   <Container column padding="0.7em 0">
//     <span style={{ fontSize: '1.5em' }}>{name}</span>
//     <span style={{ fontSize: '1.2em', color: 'gray' }}>+ More Details</span>
//   </Container>
// )

const tabs = {
  details: 'Details',
  ingredients: 'Ingredients',
  suggested: 'Suggested Use',
}

const reducer = (state, action) => {
  switch (action.type) {
    case tabs.details:
      return { ...state, details: action.value }
    case tabs.ingredients:
      return { ...state, ingredients: action.value }
    case tabs.suggested:
      return { ...state, suggested: action.value }
    default:
      return { ...action.value }
  }
}

const initialState = {
  details: '',
  ingredients: '',
  suggested: '',
}

const ProductSummary = product => {
  const {
    autoship,
    configurable_options = null,
    configurable_product_options_selection = null,
    description,
    image,
    ingredient,
    media_gallery,
    name,
    related_products,
    sku,
    stock_status,
    suggested_use,
  } = product

  const {
    prismicData: {
      prismicProductDetailPage: {
        add,
        coming_soon,
        details,
        ingredients,
        out_of_stock,
        suggested_use: suggestedUseText,
        you_may_also_like,
      },
    },
  } = usePrismic()

  const { userType, isEventSite, isVipSite } = useAuthContext()
  const { isPcOfferInCart, isAmbOfferInCart, addToAutoShip, autoShipDate } =
    useCartContext()

  const {
    addToCart,
    count,
    decrement,
    increment,
    isLoading,
    selectedOption,
    handleSetOption,
  } = useAddToCart(product)

  const { ProductPricing } = useShowProductPrice(product)

  const [cartOption, setCartOption] = useState('')
  const [tabState, dispatch] = useReducer(reducer, initialState)
  const [activeTab, setActiveTab] = useState(null)
  const [showSignIn, setShowSignIn] = useState(false)

  useEffect(() => {
    dispatch({
      type: null,
      value: {
        details: details[0].text,
        ingredients: ingredients[0].text,
        suggested: suggestedUseText[0].text,
      },
    })
    setActiveTab(details[0].text)
  }, [])

  const sortedImages = media_gallery?.sort((a, b) => a.position - b.position)
  const isOutOfStock = stock_status === 'OUT_OF_STOCK'

  const nextAutoshipDate = autoShipDate

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL') &&
    autoship

  const handleDisableButton = () => {
    if (showAutoShip) {
      if (configurable_options) {
        return !selectedOption || !cartOption
      }
      return !cartOption
    }
    if (configurable_options) {
      return !selectedOption
    }
    return false
  }

  const shouldDisableButton = isLoading || isOutOfStock || handleDisableButton()

  const handleAddProduct = async () => {
    if (cartOption === 'both') {
      await addToCart(count)
      addToAutoShip(product, count)
    } else if (cartOption === 'autoship') {
      addToAutoShip(product, count)
    } else {
      await addToCart(count)
    }
  }

  const buildConfigOptions = () => {
    const optionsDataArr = configurable_options?.[0].values
    let optionsDataWithImages = {}

    optionsDataArr.forEach((option, i) => {
      const imageData = configurable_product_options_selection.media_gallery[i]
      let optionWithImage = { ...option, image: imageData }
      optionsDataWithImages[option.uid] = optionWithImage
    })
    return optionsDataWithImages
  }

  const tabContent = content => (
    <TabItemContent>
      {content ? (
        <DescContainer dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <Container padding="0 0 1em 0" justify="center">
          <h1>{coming_soon[0].text}</h1>
        </Container>
      )}
    </TabItemContent>
  )

  const renderActiveTab = activeTab => {
    if (activeTab === tabState.details) return tabContent(description?.html)
    if (activeTab === tabState.ingredients) return tabContent(ingredient)
    if (activeTab === tabState.suggested) return tabContent(suggested_use)
  }

  return (
    <Wrapper>
      {showSignIn && (
        <SignInModal open={showSignIn} setShowModal={setShowSignIn} />
      )}
      {!!configurable_options ? (
        <ConfigProduct
          name={name}
          image={image}
          configurableOptions={buildConfigOptions()}
          setSelected={handleSetOption}
        >
          <ProductPricing />
        </ConfigProduct>
      ) : (
        <>
          <PageHeader>&nbsp;</PageHeader>
          <Container justify="center" align="center" maxWidth="800px">
            <Container justify="flex-end" align="center">
              <ProductImages mediaGallery={sortedImages} image={image} />
            </Container>
            <Container column align="flex-start">
              <div style={{ marginBottom: '1em' }}>
                <StyledSpan data-qa={`title-${sku}`} isTitle>
                  {name}
                </StyledSpan>
              </div>
              <ProductPricing />
              {/* <Container align="center" padding="1em 0">
            <Rating
              rating={averageReview}
              maxRating={5}
              size="large"
              icon="star"
            />
            <span
              style={{ color: 'blue', marginLeft: '0.5em' }}
            >{`(${review_count})`}</span>
          </Container>
          {related_products?.map(product => (
            <RelatedProduct key={product.url_key} {...product} />
          ))} */}
            </Container>
          </Container>
        </>
      )}
      <Container column align="center" justify="center" margin="1em">
        <StyledSpan data-qa={`count-${count}`} count>
          {count}
        </StyledSpan>
        <Container maxWidth="500px">
          <MinusButton
            data-qa={`minusButton-${sku}`}
            onClick={() => decrement()}
          >
            <span style={{ fontSize: '2em' }}>-</span>
          </MinusButton>
          <PlusButton data-qa={`plusButton-${sku}`} onClick={() => increment()}>
            <span style={{ fontSize: '2em' }}>+</span>
          </PlusButton>
        </Container>
      </Container>
      {showAutoShip ? (
        <Container maxWidth="600px" padding="1em">
          <AddToAutoship
            cartOption={cartOption}
            setCartOption={setCartOption}
            nextAutoshipDate={nextAutoshipDate}
          />
        </Container>
      ) : null}
      <AddButton
        content={isOutOfStock ? out_of_stock[0].text : add[0].text}
        onClick={handleAddProduct}
        loading={isLoading}
        disabled={shouldDisableButton}
        data-qa={`add-${sku}`}
      />
      <Container column align="center" justify="center">
        <TabWrapper>
          {Object.values(tabState).map((tabItem, index) => (
            <div key={index}>
              <TabItem
                className={tabItem === activeTab ? 'active' : ''}
                onClick={() => setActiveTab(tabItem)}
                data-qa={`${tabItem}-${sku}`}
              >
                {tabItem}
              </TabItem>
            </div>
          ))}
        </TabWrapper>
        {renderActiveTab(activeTab)}
      </Container>
      {related_products?.length > 0 ? (
        <Container
          column
          align="center"
          style={{ borderTop: '1px solid black', paddingTop: '1em' }}
        >
          <h2>{you_may_also_like[0].text}</h2>
          <CardsContainer>
            {related_products?.map(product => (
              <ProductCard key={product.url_key} {...product} />
            ))}
          </CardsContainer>
        </Container>
      ) : null}
    </Wrapper>
  )
}

export default ProductSummary
