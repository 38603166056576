import toast from 'react-hot-toast'
// Services
import { QService } from '../services/q-services'
// Types
import {
  AddToAutoShip,
  AutoShipDataResponse,
  ManageAutoShipTypes,
  UpdateAutoShip,
  UpdateAutoShipItems,
  UpdateProcessDate,
} from './../types/AutoShipTypes'
import { UserShippingAddress } from '../types/CartTypes'

const create = async (
  data: AddToAutoShip,
  updateState: (res: AutoShipDataResponse) => void
) => {
  if (!data.dateStart) return

  await QService.AutoShip.addTo(data)
    .then(({ addAutoShip }) => addAutoShip)
    .then(async ({ autoShipId, dateNextProcess }) =>
      updateProcessDate({ autoShipId, dateNextProcess }, updateState)
    )
    .then(() => toast.success('Subscriptions created!'))
    .catch(e => {
      console.log(e)
      toast.error('Something went wrong. Please try again.')
    })
}

const updateProcessDate = async (
  data: UpdateProcessDate,
  updateState: (res: AutoShipDataResponse) => void
) => {
  await QService.AutoShip.updateDateNextProcess(data)
    .then(({ updateAutoShipDateNextProcess }) => updateAutoShipDateNextProcess)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error('Something went wrong. Please try again.')
    })
}

const updateItems = async (
  data: UpdateAutoShipItems,
  updateState: (res: AutoShipDataResponse) => void
) => {
  await QService.AutoShip.updateItems(data)
    .then(({ updateAutoShipItems }) => updateAutoShipItems)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error('Something went wrong. Please try again.')
    })
}

const update = async (
  data: UpdateAutoShip,
  updateState: (res: AutoShipDataResponse) => void
) => {
  await QService.AutoShip.update(data)
    .then(({ updateAutoShip }) => updateAutoShip)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error('Something went wrong. Please try again.')
    })
}

const skipMonth = async (
  autoShipId: number,
  updateState: (res: AutoShipDataResponse) => void
) => {
  await QService.AutoShip.skipMonth(autoShipId)
    .then(({ skipAutoShipMonth }) => skipAutoShipMonth)
    .then(updateState)
    .catch(e => {
      console.log(e)
      toast.error('Something went wrong. Please try again.')
    })
}

const manageAutoShip: ManageAutoShipTypes = {
  create,
  updateProcessDate,
  updateItems,
  update,
  skipMonth,
}

export default manageAutoShip

export const buildAutoShipAddress = (
  address: Partial<UserShippingAddress>
) => ({
  address1: address.street[0],
  address2: address?.street[1] || '',
  city: address.city,
  state: address.region.region_code,
  zip: address.postcode,
  countryCode: address.country_code,
})

export const buildAutoShipItems = itemsArr =>
  itemsArr.map(({ product, quantity }) => ({
    quantity,
    productName: product.name,
    sku: product.sku,
    dS_ItemId: Number(product.ds_itemid),
    magentoEntityId: 6,
    isActive: true,
  }))
