import React from 'react'
// Components
import ReorderButton from '../Buttons/ReorderButton'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  OrderCostInfo,
  OrderCostItemTitle,
  OrderCostWrapper,
  ProductCostInfo,
  StyledSpan,
  TrackingInfo,
  TrackingAnchor,
  OrderCostTrackingWrapper  
} from './Orders.styles'

const OrderCost = ({
  discounts,
  grand_total,
  isOfferInOrder,
  orderNumber,
  pvTotal = 0,
  subtotal,
  total_shipping,
  total_tax,
  shipments  
}) => {
  const { userType } = useAuthContext()

  const {
    prismicData: {
      prismicOrderHistoryPage: {
        subtotal: subTotalText,
        tax,
        shipping,
        total,
        total_pv,
        order_again,
      },
    },
  } = usePrismic()

  const grandTotalValue = `$${grand_total?.value?.toFixed(2) ?? ''}`
  const shippingTotalValue = `$${total_shipping?.value?.toFixed(2) ?? ''}`
  const subTotalValue = `$${subtotal?.value?.toFixed(2) ?? ''}`
  const taxesTotalValue = `$${total_tax?.value?.toFixed(2) ?? ''}`

  // const discountValue = `-$${discounts[0]?.amount?.value?.toFixed(2) ?? null}`
  const discountValue = discounts[0]?.amount?.value
    ? `-$${discounts[0]?.amount?.value?.toFixed(2)}`
    : null
  const shouldShowDiscounts = discounts?.length
  const tracking = shipments?.map(ship => ship.tracking ?? []).reduce((arr, curVal) => {
    return arr.concat(curVal)
  }, [])
  for (const track of tracking) {
    let shipUrl = `https://google.com/search?q=${track.number}`
    switch (track.carrier.toLowerCase()) {
      case 'dhl':
        shipUrl = `https://www.dhl.com/en/express/tracking.html?AWB=${track.number}&brand=DHL`
        break
      case 'ups':
        shipUrl = `https://www.ups.com/track?trackingNumber=${track.number}`
        break
      case 'usps':
        shipUrl = `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${track.number}`
        break
      case 'fedex':
        shipUrl = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${track.number}`
        break
    }
    track.shipUrl = shipUrl
    
  }
  return (
    <>
      <OrderCostTrackingWrapper>
         <TrackingInfo>
          {tracking.map(track => (
              <TrackingAnchor key={track.number} href={track.shipUrl} target='_blank'>
                Track My Shipment
              </TrackingAnchor>
          ))}
        </TrackingInfo>
        <OrderCostWrapper>
          <OrderCostInfo>
            <OrderCostItemTitle data-qa={`orderSubTotalText-${orderNumber}`} bold>
              {subTotalText[0].text}
            </OrderCostItemTitle>
            <ProductCostInfo>
              <StyledSpan
                data-qa={`orderSubTotal-${orderNumber}`}
                textAlign="right"
                width="100%"
              >
                {subTotalValue}
              </StyledSpan>
            </ProductCostInfo>
          </OrderCostInfo>
            {shouldShowDiscounts ? (
            <OrderCostInfo>
              <OrderCostItemTitle data-qa="orderDiscountText" bold>
                {discounts[0].text}
              </OrderCostItemTitle>
              <ProductCostInfo>
                <StyledSpan
                  data-qa={`orderDiscount-${orderNumber}`}
                  discount
                  textAlign="right"
                  width="100%"
                >
                  {discountValue}
                </StyledSpan>
              </ProductCostInfo>
            </OrderCostInfo>
            ) : null}
          <OrderCostInfo>
            <OrderCostItemTitle data-qa={`orderTaxText-${orderNumber}`} bold>
              {tax[0].text}
            </OrderCostItemTitle>
            <ProductCostInfo>
              <StyledSpan
                data-qa={`orderTax-${orderNumber}`}
                textAlign="right"
                width="100%"
              >
                {taxesTotalValue}
              </StyledSpan>
            </ProductCostInfo>
          </OrderCostInfo>
          <OrderCostInfo>
            <OrderCostItemTitle data-qa={`orderShippingText-${orderNumber}`} bold>
              {shipping[0].text}
            </OrderCostItemTitle>
            <ProductCostInfo>
              <StyledSpan
                data-qa={`orderShipping-${orderNumber}`}
                textAlign="right"
                width="100%"
              >
                {shippingTotalValue}
              </StyledSpan>
            </ProductCostInfo>
          </OrderCostInfo>
          <OrderCostInfo>
            <OrderCostItemTitle data-qa={`orderTotalText-${orderNumber}`} bold>
              {total[0].text}
            </OrderCostItemTitle>
            <ProductCostInfo>
              <StyledSpan
                data-qa={`orderTotal-${orderNumber}`}
                textAlign="right"
                width="100%"
              >
                {grandTotalValue}
              </StyledSpan>
            </ProductCostInfo>
          </OrderCostInfo>
          {userType === 'AMBASSADOR' ? (
            <OrderCostInfo>
              <OrderCostItemTitle data-qa={`orderPVText-${orderNumber}`} bold>
                {total_pv[0].text}
              </OrderCostItemTitle>
              <ProductCostInfo>
                <StyledSpan
                  data-qa={`orderPV-${orderNumber}`}
                  textAlign="right"
                  width="100%"
                >
                  {pvTotal}
                </StyledSpan>
              </ProductCostInfo>
            </OrderCostInfo>
          ) : null}
        </OrderCostWrapper>       
      </OrderCostTrackingWrapper>
      {!isOfferInOrder && (
        <ReorderButton
          data-qa={`reorderButton-${orderNumber}`}
          content={order_again[0].text}
          orderNumber={orderNumber}
        />
      )}
    </>
  )
}

export default React.memo(OrderCost)
