import {
  GET_CARDS_ON_FILE,
  GET_ONE_TIME_NEXIO_TOKEN,
  NEXIO_POST_TRANSACTION,
  SAVE_CARD_TO_ASSOCIATE,
  PROCESS_PAYPAL_ORDER,
} from '../graphql/payments'

import {
  CreditCard,
  CreditCardsForAssociateResponse,
  NexioPostTransactionResponse,
  NexioPostTransData,
  OneTimeTokenResponse,
} from '../../../types/PaymentsTypes'

export const Payments = client => ({
  getNexioOneTimeCode: async (): Promise<OneTimeTokenResponse> => {
    const {
      nexioOneTimeTokenIframe: { token },
    } = await client.request(GET_ONE_TIME_NEXIO_TOKEN)
    return token
  },
  getCardsOnFile: async (
    associateId: number
  ): Promise<CreditCardsForAssociateResponse> => {
    const { nexioCreditCardsForAssociate } = await client.request(
      GET_CARDS_ON_FILE,
      {
        associateId,
      }
    )
    return nexioCreditCardsForAssociate
  },
  nexioPostTransaction: async (
    data: NexioPostTransData
  ): Promise<NexioPostTransactionResponse> => {
    const { nexioPostTransaction } = await client.request(
      NEXIO_POST_TRANSACTION,
      data
    )
    return nexioPostTransaction
  },
  saveCardData: async (associateId, cardData: CreditCard) =>
    await client.request(SAVE_CARD_TO_ASSOCIATE, { associateId, cardData }),
  processPayPalOrder: async data => {
    const result = await client.request(PROCESS_PAYPAL_ORDER, data)
    return result.processPayPalOrder.success
  },
})
