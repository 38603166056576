import React, { useState } from 'react'
import { Modal, Checkbox, Header } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Hooks
import useShowError from '../../hooks/useShowError'
// Components
import { Primary as SubmitButton, Warning as CancelButton } from '../'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Container,
  ErrorMsg,
  Logo,
  StyledForm,
  StyledInput,
  StyledModal,
} from './Verification.styles'
// Utils
import { validationSchema } from '../../utils/validations'
import { encodeEmail, encodePhone } from '../../utils/encodeCreds'

const SendVerification = ({
  cancel,
  dsUser,
  isOpen,
  saveVerificationData,
  sendVerificationCallback,
}) => {
  const {
    prismicData: {
      prismicSendVerificationModal: {
        cancel: cancelText,
        continue: continueText,
        header,
        incorrect_email_error,
        incorrect_phone_error,
        logo,
        password_placeholder,
        send_verification_code_to_email_address,
        send_verification_code_to_phone_number,
        verify_email_placeholder,
        verify_phone_placeholder,
        verify_using_your_back_office_password,
      },
    },
  } = usePrismic()

  const [open, setOpen] = useState(isOpen)
  const [checked, setChecked] = useState(null)

  const [error, setError, showError] = useShowError()

  const {
    reset,
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const resetAndShowError = message => {
    reset()
    setError(message)
  }

  const onSubmit = data => {
    if (checked === 'email') {
      if (data.email !== dsUser.emailAddress) {
        resetAndShowError(incorrect_email_error[0].text)
      } else {
        saveVerificationData(prev => ({
          ...prev,
          method: 'Email',
          data: data.email,
        }))
        sendVerificationCallback('EMAIL', data.email)
      }
    } else if (checked === 'phone') {
      if (data.phone !== dsUser.primaryPhoneNumber) {
        resetAndShowError(incorrect_phone_error[0].text)
      } else {
        saveVerificationData(prev => ({
          ...prev,
          method: 'SMS',
          data: data.phone,
        }))
        sendVerificationCallback('SMS', data.phone)
      }
    } else {
      saveVerificationData(prev => ({
        ...prev,
        method: 'DIRECT_SCALE',
        data: data.password,
      }))
      sendVerificationCallback('DIRECT_SCALE', data.password, resetAndShowError)
    }
  }

  const handleCheckbox = (e, { value }) => {
    setChecked(value)
    setFocus(value)
    setError('')
    reset()
  }

  const handleInputFocus = value => {
    setChecked(value)
    setError('')
  }

  const handleClose = () => {
    cancel()
    setOpen(false)
  }

  const encodedPhone = encodePhone(dsUser?.primaryPhoneNumber) ?? '???'
  const encodedEmail = encodeEmail(dsUser?.emailAddress) ?? '???'

  return (
    <StyledModal
      centered={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      onClose={handleClose}
      open={open}
    >
      <Container direction="column">
        <Logo src={logo.url} />
        <Modal.Description>
          <Header textAlign="center">{header[0].text}</Header>
        </Modal.Description>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {error && showError()}
          <Container direction="column" padding="2em 0 0 0">
            <Checkbox
              radio
              name="password"
              value="password"
              label={{
                children: (
                  <Container direction="column" padding="0">
                    <span>
                      {verify_using_your_back_office_password[0].text}
                    </span>
                  </Container>
                ),
              }}
              checked={checked === 'password'}
              onChange={handleCheckbox}
            />
            <StyledInput
              {...register('password')}
              onFocus={() => handleInputFocus('password')}
              placeholder={password_placeholder[0].text}
            />
          </Container>
          <Container direction="column">
            <Checkbox
              radio
              name="email"
              value="email"
              label={{
                children: (
                  <Container direction="column" padding="0">
                    <span>
                      {send_verification_code_to_email_address[0].text}
                    </span>
                    <span>{encodedEmail}</span>
                  </Container>
                ),
              }}
              checked={checked === 'email'}
              onChange={handleCheckbox}
            />
            <StyledInput
              {...register('email')}
              onFocus={() => handleInputFocus('email')}
              placeholder={verify_email_placeholder[0].text}
            />
          </Container>
          <Container direction="column">
            <Checkbox
              radio
              name="phone"
              value="phone"
              label={{
                children: (
                  <Container direction="column" padding="0">
                    <span>
                      {send_verification_code_to_phone_number[0].text}
                    </span>
                    <span>{encodedPhone}</span>
                  </Container>
                ),
              }}
              checked={checked === 'phone'}
              onChange={handleCheckbox}
            />
            <StyledInput
              {...register('phone')}
              onFocus={() => handleInputFocus('phone')}
              placeholder={verify_phone_placeholder[0].text}
            />
            <ErrorMsg>{errors.email?.message}</ErrorMsg>
          </Container>
          <SubmitButton type="submit">{continueText[0].text}</SubmitButton>
          <CancelButton onClick={cancel}>{cancelText[0].text}</CancelButton>
        </StyledForm>
      </Container>
    </StyledModal>
  )
}

export default SendVerification
