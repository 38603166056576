import { Modal, Header, Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { Primary as PrimaryButton } from '..'

export const StyledModal = styled(Modal)`
  width: 80% !important;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px !important;
  }

  &&& {
    &.ui.modal > .content {
      padding: 40px 10px !important;
    }
    i.close {
      top: 5px !important;
      left: 5px;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.black};
      border-radius: 50%;
      opacity: 1;
      width: 24px;
      height: 24px;
      padding-top: 5px;
      font-size: 15px;
      font-weight: 400;
    }
  }
`

export const StyledModalContent = styled(Modal.Content)`
  padding: 10px !important;
`

export const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: 0 !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

interface ContainerProps {
  direction: 'column' | 'row'
  justify: string
  align: string
  padding: string
  maxWidth: string
  textAlign: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  padding: 0 30px;
`

interface StyledTextProps {
  align: string
}

export const StyledText = styled.span<Partial<StyledTextProps>>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
  text-align: ${props => props.align || 'center'};

  @media ${({ theme }) => theme.device.sm} {
    font-size: 18px;
  }
`

export const StyledArqLogo = styled(Image)`
  &&& {
    width: 64px;
    margin: 15px 0;

    @media ${({ theme }) => theme.device.sm} {
      width: 128px;
      margin: 20px 0;
    }
  }
`

export const AppStoreLogoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;

  @media ${({ theme }) => theme.device.sm} {
    margin: 20px 15px;
  }
`

export const AppStoreLogo = styled(Image)`
  height: 45px;

  @media ${({ theme }) => theme.device.sm} {
    height: 70px;
  }
`

export const StyledMyQFitLogo = styled(Image)`
  &&& {
    width: 80px;
    margin: 15px 0;

    @media ${({ theme }) => theme.device.sm} {
      width: 128px;
      margin: 20px 0;
    }
  }
`
