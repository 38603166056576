import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { FilterWrapper, ResetButton } from './Menu.styles'

const FilterSortMenu = ({ types, benefits, handleFilter }) => {
  const [selected, setSelected] = useState(null)
  const {
    prismicData: {
      prismicProductsPage: { by_type, by_benefit, all_products },
    },
  } = usePrismic()

  const typeOptions = Object.entries(types).map(type => {
    const urlKey = type[0]
    const name = type[1].name
    return { key: urlKey, text: name, value: urlKey }
  })
  const benefitOptions = Object.entries(benefits).map(benefit => {
    const urlKey = benefit[0]
    const name = benefit[1].name
    return { key: urlKey, text: name, value: urlKey }
  })

  const handleChange = (e, { value, text }) => {
    setSelected(value)
    handleFilter(value, text)
  }

  const resetFilter = () => {
    setSelected(null)
    handleFilter('', '')
  }

  return (
    <FilterWrapper>
      {!!selected && (
        <ResetButton onClick={resetFilter}>{all_products[0].text}</ResetButton>
      )}
      <Dropdown
        basic
        direction="left"
        icon={false}
        item
        onChange={handleChange}
        options={benefitOptions}
        selectOnBlur={false}
        text={by_benefit[0].text}
        value={selected}
      />
      <span>&nbsp;|&nbsp;</span>
      <Dropdown
        basic
        direction="left"
        icon={false}
        item
        onChange={handleChange}
        options={typeOptions}
        selectOnBlur={false}
        text={by_type[0].text}
        value={selected}
      />
    </FilterWrapper>
  )
}

export default FilterSortMenu
