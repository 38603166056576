import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { navigate } from 'gatsby'
// Components
import { Seo, Primary as SubmitButton, Loading } from '..'
import infoIcon from '../../svg/InfoIcon.svg'
import NextStepModal from './NextStepModal'
// Utils
import { enrollmentSchema } from '../../utils/validations'
import { QService } from '../../services/q-services'
import { formatParagraph } from '../../utils/prismicUtils'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Wrapper,
  Container,
  QLogo,
  PageHeader,
  PageComment,
  SectionTitle,
  StyledForm,
  ErrorLabel,
  SuccessLabel,
  StyledInput,
  StyledCheckbox,
  StyledDivider,
  ShopQUrl,
  InfoIcon,
  ThemedPopup,
  SlugLoadingSpinner,
  Gap,
  StyledSelect,
} from './EnrollmentForm.styles'

const EnrollmentForm = () => {
  const {
    qUser,
    updateQUserFromAmbEnrollmentForm,
    manageReferral: { referralData },
    isAuthLoading,
  } = useAuthContext()

  const {
    prismicData: {
      prismicCartPage: {
        agree_receive_sms_and_email,
        understand_and_agree_policy,
      },
    },
  } = usePrismic()

  const [identification, setIdentification] = useState('personal')
  const [addSpouseAsCoAmbassador, setAddSpouseAsCoAmbassador] = useState(false)
  const [includeBusinessPartner, setIncludeBusinessPartner] = useState(false)
  const [receiveText, setReceiveText] = useState(false)
  const [send1099FormElectronically, setSend1099FormElectronically] =
    useState(false)
  const [areTermsAccepted, setAreTermsAccepted] = useState(false)
  // Slug
  const [slug, setSlug] = useState('')
  const [loadingIsSlugAvailable, setLoadingIsSlugAvailable] = useState(false)
  const [isSlugAvailable, setIsSlugAvailable] = useState(false)
  const [showSlugAvailableMessage, setShowSlugAvailableMessage] =
    useState(false)
  // Popups
  const [ssnPopupOpen, setSsnPopupOpen] = useState(false)
  const [einPopupOpen, setEinPopupOpen] = useState(false)
  const [ten99PopupOpen, setTen99PopupOpen] = useState(false)
  // Submit
  const [loadingSubmission, setLoadingSubmission] = useState(false)
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState(null)
  const [isNextStepModalOpen, setIsNextStepModalOpen] = useState(false)

  // GET SAVED ENROLLMENT FORM DATA AND SET AS DEFAULT VALUES
  const savedValues = localStorage.getItem('enrollmentForm')
  const defaultFormValues = savedValues
    ? JSON.parse(savedValues)
    : {
        includeBusinessPartner: false,
        addSpouseAsCoAmbassador: false,
        address1: '',
        address2: '',
        areTermsAccepted: false,
        dateBirth: '',
        companyName: '',
        city: '',
        einFirst: '',
        einLast: '',
        emailAddress: qUser?.emailAddress,
        identification: 'personal',
        legalFirstName: qUser?.firstName,
        legalLastName: qUser?.lastName,
        zip: '',
        receiveText: false,
        send1099FormElectronically: false,
        slug: '',
        ssnFirst: '',
        ssnLast: '',
        ssnMiddle: '',
        state: '',
        primaryPhoneNumber: '',
      }

  const {
    register,
    setFocus,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(enrollmentSchema),
    mode: 'onBlur',
    defaultValues: defaultFormValues,
  })

  const handleChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    if (fieldName === 'ssnFirst' && event.target.value.length === 3) {
      setFocus('ssnMiddle')
    } else if (fieldName === 'ssnMiddle' && event.target.value.length === 2) {
      setFocus('ssnLast')
    } else if (fieldName === 'ssnLast' && event.target.value.length === 4) {
      setFocus('legalFirstName')
    } else if (fieldName === 'einFirst' && event.target.value.length === 2) {
      setFocus('einLast')
    } else if (fieldName === 'einLast' && event.target.value.length === 7) {
      setFocus('companyName')
    }
  }

  const validateSlug = async () => {
    if (slug.length < 3) return
    setLoadingIsSlugAvailable(true)
    try {
      const slugData = await QService.User.isSlugAvailable({
        slug,
      })
      setIsSlugAvailable(slugData)
    } catch (error) {
      console.log('error', error)
    }
    setLoadingIsSlugAvailable(false)
    setShowSlugAvailableMessage(true)
  }

  const onSubmit = async (data): Promise<void> => {
    if (!isSlugAvailable) return setShowSlugAvailableMessage(true)
    setLoadingSubmission(true)
    setSubmissionErrorMessage(null)
    const {
      address1,
      address2,
      city,
      state,
      zip,
      ssnFirst,
      ssnMiddle,
      ssnLast,
      einFirst,
      einLast,
      addSpouseAsCoAmbassador,
      includeBusinessPartner,
      identification,
      ...rest
    } = data
    const variables = {
      ...qUser,
      ...rest,
      address: {
        address1,
        address2,
        city,
        state,
        zip,
      },
      receiveEmail: true,
      receiveText,
      socialSecurityNumber:
        identification === 'personal'
          ? `${ssnFirst}${ssnMiddle}${ssnLast}`
          : null,
      employerIdentificationNumber:
        identification === 'business' ? `${einFirst}${einLast}` : null,
      includeBusinessPartner:
        identification === 'personal'
          ? addSpouseAsCoAmbassador
          : includeBusinessPartner,
    }
    await updateQUserFromAmbEnrollmentForm(variables)
      .then(() => {
        localStorage.removeItem('enrollmentForm')
        setIsNextStepModalOpen(true)
        setSubmissionErrorMessage(null)
      })
      .catch(error =>
        setSubmissionErrorMessage(
          `Sorry there was an error: ${error.response.errors[0].message}`
        )
      )
    setLoadingSubmission(false)
  }

  const handleSaveDataOnBlur = () => {
    let currentValues = getValues()
    delete currentValues.ssnFirst
    delete currentValues.ssnMiddle
    delete currentValues.ssnLast
    localStorage.setItem('enrollmentForm', JSON.stringify(currentValues))
  }

  // SHOW LOADING SCREEN IF AUTHENTICATION IS LOADING
  if (isAuthLoading)
    return <Loading loading={isAuthLoading} message="Loading..." />

  return (
    <>
      <Seo title="Sign Up" />
      <Wrapper>
        <Container>
          <QLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        </Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <PageHeader>Ambassador Enrollment</PageHeader>
          {referralData?.ambassadorName && (
            <PageComment>{`(referred by ${referralData?.ambassadorName})`}</PageComment>
          )}
          <SectionTitle>Tax Identification</SectionTitle>
          <>
            <Container margin="20px 0 0;">
              {errors.identification && (
                <ErrorLabel>{errors.identification?.message}</ErrorLabel>
              )}
            </Container>
            <Container>
              <Container justify="flex-start" direction="row">
                <StyledCheckbox
                  style={{ fontSize: '18px' }}
                  radio
                  name="identificationGroup"
                  label="Individual/Personal (SSN)"
                  value="personal"
                  checked={identification === 'personal'}
                  {...register('identification')}
                  onChange={() => {
                    setIdentification('personal')
                    setIncludeBusinessPartner(false)
                    setValue('identification', 'personal')
                    setValue('includeBusinessPartner', false)
                    clearErrors(['einFirst', 'einLast'])
                  }}
                />
                <ThemedPopup
                  content="I'm enrolling as an individual using my Social Security Number (SSN)."
                  on="click"
                  onClose={() => setSsnPopupOpen(false)}
                  onOpen={() => setSsnPopupOpen(true)}
                  open={ssnPopupOpen}
                  position="bottom right"
                  trigger={<InfoIcon src={infoIcon} />}
                />
              </Container>
            </Container>

            <Container margin="0 0 0 40px;">
              <Container margin="10px 0 0;">
                {errors.ssnFirst && (
                  <ErrorLabel>{errors.ssnFirst?.message}</ErrorLabel>
                )}
                {errors.ssnMiddle && (
                  <ErrorLabel>{errors.ssnMiddle?.message}</ErrorLabel>
                )}
                {errors.ssnLast && (
                  <ErrorLabel>{errors.ssnLast?.message}</ErrorLabel>
                )}
              </Container>
              <Container direction="row" align="flex-end" justify="flex-start">
                <StyledInput
                  {...register('ssnFirst')}
                  placeholder="***"
                  width="60px"
                  maxLength={3}
                  onChange={event => handleChanged(event, 'ssnFirst')}
                  disabled={identification === 'business'}
                  defaultValue={''}
                />
                <StyledDivider />
                <StyledInput
                  {...register('ssnMiddle')}
                  placeholder="**"
                  width="40px"
                  maxLength={2}
                  onChange={event => handleChanged(event, 'ssnMiddle')}
                  disabled={identification === 'business'}
                  defaultValue={''}
                />
                <StyledDivider />
                <StyledInput
                  {...register('ssnLast')}
                  placeholder="****"
                  width="70px"
                  maxLength={4}
                  onChange={event => handleChanged(event, 'ssnLast')}
                  disabled={identification === 'business'}
                  defaultValue={''}
                />
              </Container>

              <Container>
                <StyledCheckbox
                  {...register('addSpouseAsCoAmbassador')}
                  label="Include my spouse as a co ambassador"
                  value="addSpouseAsCoAmbassador"
                  checked={addSpouseAsCoAmbassador === true}
                  onChange={() => {
                    setAddSpouseAsCoAmbassador(prev => {
                      setValue('addSpouseAsCoAmbassador', !prev)
                      return !prev
                    })
                  }}
                  disabled={identification === 'business'}
                />
              </Container>
            </Container>

            <Container margin="10px 0 0;">
              <Container justify="flex-start" direction="row">
                <StyledCheckbox
                  style={{ fontSize: '18px' }}
                  radio
                  name="identificationGroup"
                  label="Business/LLC (EIN)"
                  value="business"
                  checked={identification === 'business'}
                  {...register('identification')}
                  onChange={() => {
                    setIdentification('business')
                    setAddSpouseAsCoAmbassador(false)
                    setValue('identification', 'business')
                    setValue('addSpouseAsCoAmbassador', false)
                    clearErrors(['ssnFirst', 'ssnMiddle', 'ssnLast'])
                  }}
                />
                <ThemedPopup
                  content="I'm enrolling as a business/LLC using my Employer Identification Number (EIN)."
                  on="click"
                  onClose={() => setEinPopupOpen(false)}
                  onOpen={() => setEinPopupOpen(true)}
                  open={einPopupOpen}
                  position="bottom center"
                  trigger={<InfoIcon src={infoIcon} />}
                />
              </Container>
            </Container>

            <Container margin="0 0 0 40px;">
              <Container margin="10px 0 0;">
                {errors.einFirst && (
                  <ErrorLabel>{errors.einFirst?.message}</ErrorLabel>
                )}
                {errors.einLast && (
                  <ErrorLabel>{errors.einLast?.message}</ErrorLabel>
                )}
              </Container>
              <Container direction="row" align="flex-end" justify="flex-start">
                <StyledInput
                  {...register('einFirst')}
                  placeholder="**"
                  width="40px"
                  maxLength={2}
                  onChange={event => handleChanged(event, 'einFirst')}
                  disabled={identification === 'personal'}
                />
                <StyledDivider />
                <StyledInput
                  {...register('einLast')}
                  placeholder="*******"
                  width="90px"
                  onChange={event => handleChanged(event, 'einLast')}
                  disabled={identification === 'personal'}
                />
              </Container>
              <Container>
                <StyledCheckbox
                  {...register('includeBusinessPartner')}
                  label="Include a business partner"
                  value="includeBusinessPartner"
                  disabled={identification === 'personal'}
                  checked={includeBusinessPartner === true}
                  onChange={() => {
                    setIncludeBusinessPartner(prev => {
                      setValue('includeBusinessPartner', !prev)
                      return !prev
                    })
                  }}
                />
              </Container>
            </Container>
          </>
          <SectionTitle>Personal Information</SectionTitle>
          <>
            <Container align="start" margin="20px 0 0">
              <span style={{ fontSize: '12px' }}>*Required fields</span>
            </Container>
            <Container>
              {identification === 'business' && (
                <>
                  {errors.companyName && (
                    <ErrorLabel>{errors.companyName?.message}</ErrorLabel>
                  )}
                  <StyledInput
                    {...register('companyName')}
                    placeholder="Business Name (if enrolling as a business)*"
                  />
                </>
              )}

              {errors.legalFirstName && (
                <ErrorLabel>{errors.legalFirstName?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('legalFirstName')}
                placeholder="Legal First Name*"
                onBlur={handleSaveDataOnBlur}
              />

              {errors.legalLastName && (
                <ErrorLabel>{errors.legalLastName?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('legalLastName')}
                placeholder="Legal Last Name*"
                onBlur={handleSaveDataOnBlur}
              />

              {errors.primaryPhoneNumber && (
                <ErrorLabel>{errors.primaryPhoneNumber?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('primaryPhoneNumber')}
                placeholder="Mobile Phone*"
                type="tel"
                onBlur={handleSaveDataOnBlur}
              />

              {errors.emailAddress && (
                <ErrorLabel>{errors.emailAddress?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('emailAddress')}
                placeholder="Email Address*"
                onBlur={handleSaveDataOnBlur}
              />
            </Container>

            <Container>
              {errors.dateBirth && (
                <ErrorLabel>{errors.dateBirth?.message}</ErrorLabel>
              )}
              <Container direction="row" align="flex-start">
                <StyledInput
                  {...register('dateBirth')}
                  placeholder="Birthdate (MM/DD/YYYY)*"
                  onBlur={handleSaveDataOnBlur}
                />
              </Container>
            </Container>
          </>
          <SectionTitle>Address</SectionTitle>
          <>
            <Container margin="20px 0 0">
              {errors.address1 && (
                <ErrorLabel>{errors.address1?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('address1')}
                placeholder="Address Line 1*"
                onBlur={handleSaveDataOnBlur}
              />

              {errors.address2 && (
                <ErrorLabel>{errors.address2?.message}</ErrorLabel>
              )}
              <StyledInput
                {...register('address2')}
                placeholder="Address Line 2"
                onBlur={handleSaveDataOnBlur}
              />

              {errors.city && <ErrorLabel>{errors.city?.message}</ErrorLabel>}
              <StyledInput
                {...register('city')}
                placeholder="City*"
                onBlur={handleSaveDataOnBlur}
              />
            </Container>

            <Container direction="row" justify="space-between" align="flex-end">
              <Container width="49%">
                {errors.state && (
                  <ErrorLabel>{errors.state?.message}</ErrorLabel>
                )}
                <StyledSelect
                  {...register('state')}
                  onBlur={handleSaveDataOnBlur}
                >
                  <option value="">State</option>
                  <option value="AK">AK</option>
                  <option value="AL">AL</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VA">VA</option>
                  <option value="VT">VT</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </StyledSelect>
              </Container>

              <Container width="49%">
                {errors.zip && <ErrorLabel>{errors.zip?.message}</ErrorLabel>}
                <StyledInput
                  {...register('zip')}
                  placeholder="Zip Code*"
                  onBlur={handleSaveDataOnBlur}
                />
              </Container>
            </Container>
          </>
          <SectionTitle>Your ShopQ Website Address</SectionTitle>
          <Container margin="10px 0 0;">
            Create a custom shop name below to share with your customers. Your
            customers must use this link in order for you to get credit when
            they shop.
          </Container>

          <Container margin="10px 0 0 0">
            {errors.slug && <ErrorLabel>{errors.slug?.message}</ErrorLabel>}
            {showSlugAvailableMessage ? (
              <>
                {isSlugAvailable ? (
                  <SuccessLabel>This store name is available!</SuccessLabel>
                ) : (
                  <ErrorLabel>This store name is NOT available</ErrorLabel>
                )}
              </>
            ) : (
              <Gap />
            )}
            <Container align="flex-end" direction="row">
              <ShopQUrl>shopq.qsciences.com/store/&nbsp;</ShopQUrl>
              <StyledInput
                style={{ marginBottom: 0 }}
                {...register('slug')}
                value={slug}
                placeholder="SampleText*"
                onChange={e => {
                  setValue('slug', e.target.value)
                  setSlug(e.target.value)
                  trigger('slug')
                  setShowSlugAvailableMessage(false)
                }}
                onBlur={validateSlug}
              />
              {loadingIsSlugAvailable && (
                <SlugLoadingSpinner
                  loading
                  size="large"
                  name="circle notched"
                />
              )}
            </Container>
          </Container>

          <SectionTitle>Terms and Conditions</SectionTitle>
          <Container margin="10px 0 0;">
            <StyledCheckbox
              {...register('receiveText')}
              label={agree_receive_sms_and_email[0].text}
              value="receiveText"
              checked={receiveText === true}
              onChange={() => {
                setReceiveText(prev => {
                  setValue('receiveText', !prev)
                  return !prev
                })
              }}
            />
          </Container>
          <Container margin="10px 0 0;">
            {errors.send1099FormElectronically && (
              <ErrorLabel>
                {errors.send1099FormElectronically?.message}
              </ErrorLabel>
            )}
            <Container justify="flex-start" direction="row">
              <StyledCheckbox
                {...register('send1099FormElectronically')}
                label="I agree to receive my 1099 form electronically.*"
                value="send1099FormElectronically"
                checked={send1099FormElectronically === true}
                onChange={() => {
                  setSend1099FormElectronically(prev => {
                    setValue('send1099FormElectronically', !prev)
                    return !prev
                  })
                }}
              />
              <ThemedPopup
                content="We will send your 1099 tax form electronically to the email address on file."
                on="click"
                onClose={() => setTen99PopupOpen(false)}
                onOpen={() => setTen99PopupOpen(true)}
                open={ten99PopupOpen}
                position="bottom right"
                trigger={<InfoIcon src={infoIcon} />}
              />
            </Container>
          </Container>

          <Container margin="10px 0 0;">
            {errors.areTermsAccepted && (
              <ErrorLabel>{errors.areTermsAccepted?.message}</ErrorLabel>
            )}
            <StyledCheckbox
              {...register('areTermsAccepted')}
              label={
                <label
                  dangerouslySetInnerHTML={{
                    __html: formatParagraph(understand_and_agree_policy[0]),
                  }}
                />
              }
              value="areTermsAccepted"
              checked={areTermsAccepted === true}
              onChange={() => {
                setAreTermsAccepted(prev => {
                  setValue('areTermsAccepted', !prev)
                  return !prev
                })
              }}
            />
          </Container>
          <Container style={{ marginTop: '20px' }}>
            <SubmitButton
              type="submit"
              // disabled={
              //   loadingSubmission ||
              //   !areTermsAccepted ||
              //   !send1099FormElectronically
              // }
              loading={loadingSubmission}
            >
              Continue
            </SubmitButton>
            {submissionErrorMessage !== null && (
              <ErrorLabel style={{ alignSelf: 'center', textAlign: 'center' }}>
                {submissionErrorMessage}
              </ErrorLabel>
            )}
          </Container>
        </StyledForm>
        <NextStepModal
          open={isNextStepModalOpen}
          setShowModal={setIsNextStepModalOpen}
        />
      </Wrapper>
    </>
  )
}

export default EnrollmentForm
