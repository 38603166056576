import React, { useState, useEffect, useContext } from 'react'
import toast from 'react-hot-toast'
import { navigate } from 'gatsby'
// Contexts
import { useAuthContext } from './AuthContext'
import { useStoreContext } from './StoreContext'
// Services
import { Magento } from '../services/magento'
import { QService } from '../services/q-services'
// Types
import {
  AutoShipItemsProduct,
  CartContextTypes,
  InitAutoShipState,
  InitCartState,
} from '../types/contexts/CartContextTypes'
import { AutoShipDataResponse } from '../types/AutoShipTypes'
// Utils
import { Logger } from '../utils/logger'
import {
  displayOutOfStockError,
  handleOutOfStock,
} from '../utils/productHelpers'
import manageCart from '../utils/cartHelpers'
import manageAutoShip, { buildAutoShipItems } from '../utils/autoShipHelpers'
import { addOneDay } from '../utils/dateHelpers'
import { enrollSkus } from '../utils/constants'

import { initialCartState, initialAutoShipState } from './initialStates'

enum UserType {
  AMBASSADOR = 'AMBASSADOR',
  PREFERRED = 'PREFERRED',
  RETAIL = 'RETAIL',
}

const Cart = React.createContext<Partial<CartContextTypes>>({})

export const useCartContext = () => useContext(Cart)

const CartProvider = ({ children }) => {
  const [
    {
      cartData,
      cartId,
      guestCartId,
      isAmbOfferInCart,
      isPcOfferInCart,
      isPlacingOrder,
      isShippingSet,
      isShowCartPopup,
    },
    setCartState,
  ] = useState<InitCartState>(initialCartState)

  const [
    {
      autoShipAddress,
      autoShipData,
      autoShipDate,
      autoShipId,
      autoShipItems,
      autoShipPaymentInformationId,
    },
    setAutoShipState,
  ] = useState<InitAutoShipState>(
    localStorage.getItem('autoShipState')
      ? JSON.parse(localStorage.getItem('autoShipState') as string)
      : initialAutoShipState
  )

  const {
    isAuthenticated,
    isAuthLoading,
    isFirstLogin,
    magentoUser,
    qUser,
    shouldAddPcFee,
    userType,
    handleSetUserState,
    manageReferral: { isEnrollmentComplete },
  } = useAuthContext()

  const { storeData, isStoreLoading } = useStoreContext()
  const ASProducts = storeData?.autoShipProducts?.products ?? []

  useEffect(() => {
    if (isAuthLoading) return
    const existingCartId = localStorage.getItem('guestCartId') ?? guestCartId
    if (qUser) userCart(existingCartId)
    else guestCart(existingCartId)
  }, [isAuthLoading])

  function findAndReturnProductInfo(sku: string) {
    return cartData?.items?.find(({ product }) => product.sku === sku)
  }

  const handleSetCustomerGroup = async (
    customerGroup: 'RETAIL' | 'PREFERRED' | 'AMBASSADOR'
  ) => {
    if (!isAuthenticated) return
    return await QService.User.setCustomerGroup(customerGroup).catch(e => {
      console.log(e)
      // e.message.includes('not authorized') && refreshAuthState()
    })
  }

  // ADD PC FEE TO CART
  useEffect(() => {
    if (!cartData) return
    const pcFeeItem = findAndReturnProductInfo(enrollSkus.pcFee)

    setCartState(prev => ({ ...prev, isPcOfferInCart: !!pcFeeItem }))

    if (!pcFeeItem) {
      if (shouldAddPcFee) {
        upgradeUserAddPcFee().then(() =>
          handleSetUserState({ shouldAddPcFee: false })
        )
        return
      }
      return
    } else {
      const { uid, quantity } = pcFeeItem
      // AUTO REMOVE PC FEE IF USER IS NOT RETAIL AND USER CART IS ACTIVE
      if (userType !== 'RETAIL' && cartData.email) {
        removeFee(uid, cartData.id)
        return
      }
      // CHECK TO ENSURE THAT ONLY (1) PCFEE EXISTS IN CART
      ensureOnlyOneQty(uid, quantity)
    }
  }, [cartData?.items, userType, shouldAddPcFee, isFirstLogin])

  // ADD AMBASSADOR FEE TO CART
  const [ambEnrollProducts, setAmbEnrollProducts] = useState({
    premium: null,
    basic: null,
  })

  const [selectedAmbEnrollSku, setSelectedAmbEnrollSku] = useState(null)

  const addSelectedAmbFeeToCart = async () => {
    if (!isAuthenticated) return navigate('/login')
    if (!cartId) return
    setCartState(prev => ({ ...prev, isAmbOfferInCart: true }))
    await upgradeToAmbassadorAddItems()
  }

  useEffect(() => {
    if (!isAuthenticated || !cartId) return
    // CHECK TO ENSURE THAT ONLY (1) AMB ENROLL FEE EXISTS IN CART
    const selectedAmbFeeItem = findAndReturnProductInfo(selectedAmbEnrollSku)
    if (selectedAmbFeeItem) {
      const { uid, quantity } = selectedAmbFeeItem
      ensureOnlyOneQty(uid, quantity)
    }
    // IF OTHER TIER AMB ENROLL FEE IS IN CART, REMOVE IT
    const nonSelectedAmbSku =
      selectedAmbEnrollSku === enrollSkus.ambPremiumSku
        ? enrollSkus.ambBasicSku
        : enrollSkus.ambPremiumSku

    const nonSelectedAmbFeeItem = findAndReturnProductInfo(nonSelectedAmbSku)
    if (nonSelectedAmbFeeItem) {
      removeFee(nonSelectedAmbFeeItem.uid, cartId)
    }
    // IF PC FEE EXISTS, REMOVE PC FEE
    const pcFeeItem = findAndReturnProductInfo(enrollSkus.pcFee)
    if (pcFeeItem) {
      removeFee(pcFeeItem.uid, cartId)
    }
    // CHECK TO ENSURE THAT ONLY (1) ARQ FEE EXISTS IN CART
    const arqFeeItem = findAndReturnProductInfo(enrollSkus.arq)
    if (arqFeeItem) {
      const { uid: arqUid, quantity: arqQuantity } = arqFeeItem
      ensureOnlyOneQty(arqUid, arqQuantity)
    }
    // CHECK TO ENSURE THAT ONLY (1) MYQFIT FEE EXISTS IN CART
    const myQFitFeeItem = findAndReturnProductInfo(enrollSkus.myQFit)
    if (myQFitFeeItem) {
      const { uid: myQFitUid, quantity: myQFitQuantity } = myQFitFeeItem
      ensureOnlyOneQty(myQFitUid, myQFitQuantity)
    }
  }, [isAuthenticated, userType, cartData?.items])

  const ensureOnlyOneQty = async (uid: string, quantity: number) => {
    if (quantity === 1) return
    const itemObj = {
      cart_item_uid: uid,
      quantity: 1,
    }
    const cartObj = {
      cart_id: cartId || guestCartId,
      cart_items: [itemObj],
    }
    await Magento.Cart.updateItemsInCart(cartObj).then(buildCart)
  }

  const upgradeUserAddPcFee = async () => {
    await handleSetCustomerGroup('PREFERRED').then(async () => {
      const getCartId = magentoUser ? cartId : guestCartId
      return await manageCart.addItemToCart(
        { sku: 'PCFEE' },
        1,
        getCartId,
        buildCart
      )
    })
  }

  const downgradeUserRemovePcFee = async (uid: string) => {
    await handleSetCustomerGroup('RETAIL').then(async () => {
      const getCartId = magentoUser ? cartId : guestCartId
      return await manageCart.removeItemFromCart(uid, getCartId, buildCart)
    })
  }

  const removeFee = async (uid: string, cartId: string) =>
    await manageCart.removeItemFromCart(uid, cartId, buildCart)

  // UPGRADE USER TO AMBASSADOR AND ADD AMBASSADOR ITEMS TO CART
  const upgradeToAmbassadorAddItems = async () => {
    await handleSetCustomerGroup('AMBASSADOR').then(async () => {
      await manageCart.addItemsToCart(
        [selectedAmbEnrollSku, enrollSkus.arq, enrollSkus.myQFit],
        1,
        cartId,
        buildCart
      )
    })
  }

  // DOWNGRADE TO CURRENT USER TYPE AND REMOVE ITEMS FROM CART
  const downgradeAmbassadorRemoveItems = async (ambSkuId, arqId, myQFitId) => {
    await handleSetCustomerGroup(userType).then(async () => {
      await manageCart.removeItemsFromCart(
        [ambSkuId, arqId, myQFitId],
        cartId,
        buildCart
      )
    })
  }

  const guestCart = async (guestCartId: string) => {
    if (guestCartId) {
      setCartState(prev => ({ ...prev, guestCartId: guestCartId }))
      handleGetCartData(guestCartId)
    } else {
      await Magento.Cart.createEmptyCart()
        .then(id => {
          localStorage.setItem('guestCartId', id)
          setCartState(prev => ({ ...prev, guestCartId: id }))
          handleGetCartData(id)
        })
        .catch(e => console.log(e))
    }
  }

  // add guestCartData state to use in mergeCart
  const userCart = async (guestCartId: string) => {
    const shouldMergeCarts = guestCartId && cartData?.items?.length
    await Magento.Cart.getActiveCartOrCreateNew()
      .then(({ customerCart }) => {
        setCartState(prev => ({ ...prev, cartId: customerCart.id }))
        if (shouldMergeCarts) {
          mergeCarts(guestCartId, customerCart.id)
          return
        }
        if (customerCart.items.length) {
          const pcFeeItem = customerCart?.items?.find(
            ({ product }) => product.sku === enrollSkus.pcFee
          )
          if (pcFeeItem) {
            handleSetCustomerGroup(UserType.PREFERRED)
          }
          const ambPremiumSkuItem = customerCart?.items?.find(
            ({ product }) => product.sku === enrollSkus.ambPremiumSku
          )
          if (ambPremiumSkuItem) {
            handleSetCustomerGroup(UserType.AMBASSADOR)
          }
          const ambBasicSkuItem = customerCart?.items?.find(
            ({ product }) => product.sku === enrollSkus.ambBasicSku
          )
          if (ambBasicSkuItem) {
            handleSetCustomerGroup(UserType.AMBASSADOR)
          }
        }
        buildCart(customerCart)
      })
      .catch(e => {
        try {
          const { customerCart } = handleOutOfStock(e)
          buildCart(customerCart)
          displayOutOfStockError(customerCart)
        } catch (e) {
          Logger.log(e)
        }
      })
  }

  const mergeCarts = async (guestCartId: string, userCartId: string) => {
    await Magento.Cart.mergeCarts({
      source_cart_id: guestCartId,
      destination_cart_id: userCartId,
    })
      .then(cart => {
        if (!cart.items.length) return
        const pcFeeItem = cart?.items?.find(
          ({ product }) => product.sku === enrollSkus.pcFee
        )
        if (pcFeeItem) {
          handleSetCustomerGroup(UserType.PREFERRED)
        }
        const ambPremiumSkuItem = cart?.items?.find(
          ({ product }) => product.sku === enrollSkus.ambPremiumSku
        )
        if (ambPremiumSkuItem) {
          handleSetCustomerGroup(UserType.AMBASSADOR)
        }
        const ambBasicSkuItem = cart?.items?.find(
          ({ product }) => product.sku === enrollSkus.ambBasicSku
        )
        if (ambBasicSkuItem) {
          handleSetCustomerGroup(UserType.AMBASSADOR)
        }
        return cart
      })

      .then(buildCart)
      .then(() => {
        localStorage.removeItem('guestCartId')
        setCartState(prev => ({ ...prev, guestCartId: '', cartId: userCartId }))
      })
      .catch(e => console.log(e))
  }

  const buildCart = cart => {
    let [totalItemCount, totalSavings, totalWholesaleSavings, wholesaleTotal] =
      [0, 0, 0, 0]

    if (cart.items) {
      cart.items = cart.items.filter(item => !!item) // when there are errors, the product item array has nulls we want to remove
      cart.items.map(item => {
        const {
          product: { price_range, wholesale_price },
          quantity,
        } = item

        const regularPrice =
          price_range?.maximum_price?.regular_price?.value ?? ''
        const regularPriceTotal = regularPrice * quantity
        const finalPrice = price_range?.maximum_price?.final_price?.value ?? ''
        const finalPriceTotal = finalPrice * quantity
        const wholesalePrice = Number(wholesale_price)
        const wholesalePriceTotal = wholesalePrice * quantity
        const wholesaleSavings = regularPriceTotal - wholesalePriceTotal
        const savings = regularPriceTotal - finalPriceTotal

        totalItemCount += quantity
        totalSavings += savings
        totalWholesaleSavings += wholesaleSavings
        wholesaleTotal += wholesalePriceTotal
      })
    }

    setCartState(prev => ({
      ...prev,
      cartData: {
        ...cart,
        total_item_count: totalItemCount,
        total_savings: totalSavings,
        total_wholesale_savings: totalWholesaleSavings,
        wholesale_total: wholesaleTotal,
      },
    }))
  }

  const handleGetCartData = (cartId?: string): Promise<void> => {
    if (!isAuthenticated) {
      Magento.Cart.getCartData({ cart_id: guestCartId || cartId })
        .then(buildCart)
        .catch(e => {
          try {
            const { customerCart } = handleOutOfStock(e)
            buildCart(customerCart)
            displayOutOfStockError(customerCart)
          } catch (e) {
            Logger.log(e)
          }
        })
      return
    }
    Magento.Cart.getActiveCartOrCreateNew()
      .then(({ customerCart }) => {
        setCartState(prev => ({ ...prev, cartId: customerCart.id }))
        buildCart(customerCart)
      })
      .catch(e => {
        try {
          const { customerCart } = handleOutOfStock(e)
          buildCart(customerCart)
          displayOutOfStockError(customerCart)
        } catch (e) {
          Logger.log(e)
        }
      })
  }

  useEffect(() => {
    if (autoShipId) {
      localStorage.removeItem('autoShipState')
      return
    }
    saveASDataToLocalStorage()
  }, [
    autoShipAddress,
    autoShipData,
    autoShipDate,
    autoShipId,
    autoShipItems,
    autoShipPaymentInformationId,
  ])

  const saveASDataToLocalStorage = () =>
    localStorage.setItem(
      'autoShipState',
      JSON.stringify({
        autoShipAddress,
        autoShipData,
        autoShipDate,
        autoShipId,
        autoShipItems,
        autoShipPaymentInformationId,
      })
    )

  useEffect(() => {
    if (!isAuthenticated || userType === 'RETAIL' || isStoreLoading) return
    autoShipInit()
  }, [isAuthenticated, userType, isStoreLoading])

  const autoShipInit = () =>
    QService.AutoShip.getId()
      .then(({ autoShips }) => {
        if (!autoShips.length) return null
        const mostRecent = autoShips.length - 1
        return autoShips[mostRecent]?.autoShipId
      })
      .then(handleGetAutoShipById)
      .catch(e => {
        console.log(e.message)
      })

  const updateAutoShipState = (data: AutoShipDataResponse) => {
    if (!data.isActive) {
      setAutoShipState({ ...initialAutoShipState })
      return
    }
    const findProductsBySku = autoShipItems => {
      let items = []
      autoShipItems.forEach(item => {
        if (!item.isActive) {
          return
        }
        const productData = ASProducts.find(product => product.sku === item.sku)
        if (!productData) return
        const combined = { ...item, product: productData }
        items.push(combined)
      })
      return items
    }
    handleSetAutoShipState({
      autoShipData: { ...data },
      autoShipId: data.autoShipId,
      autoShipDate: addOneDay(data.dateNextProcess),
      autoShipItems: findProductsBySku(data.autoShipItems),
    })
  }

  const handleGetAutoShipById = (id: number = 0) => {
    if (!id) return
    // setAutoShipState({ ...initialAutoShipState })
    const findProductsBySku = autoShipItems => {
      let items = []
      autoShipItems.forEach(item => {
        if (!item.isActive) {
          return
        }
        const productData = ASProducts.find(product => product.sku === item.sku)
        if (!productData) return
        const combined = { ...item, product: productData }
        items.push(combined)
      })
      return items
    }
    QService.AutoShip.getData(id || autoShipId)
      .then(({ autoShip }) => {
        if (!autoShip.isActive) return
        setAutoShipState({
          autoShipAddress: autoShip.address,
          autoShipData: { ...autoShip },
          autoShipDate: addOneDay(autoShip.dateNextProcess),
          autoShipId: autoShip.autoShipId,
          autoShipItems: findProductsBySku(autoShip.autoShipItems),
          autoShipPaymentInformationId: autoShip.associatePaymentInformationId,
        })
      })
      .catch(e => {
        console.log(e.message)
      })
  }

  const addToAutoShip = async (product, quantity: number) => {
    let isDuplicate = false
    const combinedItems = autoShipItems.map(item => {
      if (item.product.sku === product.sku) {
        isDuplicate = true
        return { ...item, quantity: item.quantity + quantity }
      }
      return item
    })

    let newItemsArray = isDuplicate
      ? combinedItems
      : ([...autoShipItems, { product, quantity }] as AutoShipItemsProduct[])

    if (autoShipId) {
      await manageAutoShip
        .updateItems(
          { autoShipId, autoShipItems: buildAutoShipItems(newItemsArray) },
          updateAutoShipState
        )
        .then(() =>
          toast.success(`${product.name} (x${quantity}) added to AutoShip`)
        )
        .catch(e => toast.error(e.message))
    } else {
      handleSetAutoShipState({
        autoShipItems: newItemsArray,
      })
      if (quantity < 0) {
        toast.success(
          `Removed ${quantity * -1} ${product.name} from AutoShip`,
          {
            icon: '❌',
          }
        )
        return
      }
      toast.success(`${product.name} (x${quantity}) added to AutoShip`)
    }
  }

  const removeFromAutoShip = async (sku: string, name: string) => {
    let items = autoShipItems.filter(item => item.product.sku !== sku)

    if (autoShipId) {
      await manageAutoShip
        .updateItems(
          { autoShipId, autoShipItems: buildAutoShipItems(items) },
          updateAutoShipState
        )
        .then(() =>
          toast.success(`${name} removed from AutoShip`, {
            icon: '❌',
          })
        )
        .catch(e => toast.error(e.message))
    } else {
      handleSetAutoShipState({
        autoShipItems: items,
      })
      toast.success(`${name} removed from AutoShip`, {
        icon: '❌',
      })
    }
  }

  const resetCartState = () => {
    setCartState({ ...initialCartState })
    setAutoShipState({ ...initialAutoShipState })
    localStorage.removeItem('autoShipState')
    localStorage.removeItem('guestCartId')
  }

  const handleSetAutoShipState = (autoShipState: Partial<InitAutoShipState>) =>
    setAutoShipState(prev => ({ ...prev, ...autoShipState }))

  const handleSetCartState = (cartState: Partial<InitCartState>) =>
    setCartState(prev => ({ ...prev, ...cartState }))

  const setIsPlacingOrder = (orderState: boolean) =>
    setCartState(prev => ({ ...prev, isPlacingOrder: orderState }))

  const showCartPopup = (visible: boolean) =>
    setCartState(prev => ({ ...prev, isShowCartPopup: visible }))

  return (
    <Cart.Provider
      value={{
        addSelectedAmbFeeToCart,
        ambEnrollProducts,
        autoShipAddress,
        autoShipData,
        autoShipDate,
        autoShipId,
        autoShipItems,
        autoShipPaymentInformationId,
        cartData,
        cartId: cartId || guestCartId,
        isAmbOfferInCart,
        isPcOfferInCart,
        isPlacingOrder,
        isShowCartPopup,
        isShippingSet,
        manageAutoShip,
        manageCart,
        addToAutoShip,
        buildCart,
        downgradeAmbassadorRemoveItems,
        downgradeUserRemovePcFee,
        handleGetAutoShipById,
        handleGetCartData,
        handleSetAutoShipState,
        handleSetCartState,
        removeFromAutoShip,
        resetCartState,
        selectedAmbEnrollSku,
        setAmbEnrollProducts,
        setIsPlacingOrder,
        setSelectedAmbEnrollSku,
        showCartPopup,
        updateAutoShipState,
        upgradeUserAddPcFee,
      }}
    >
      {children}
    </Cart.Provider>
  )
}

export default CartProvider
