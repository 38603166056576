import { Modal, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import check from '../../svg/CheckCircle.svg'

export const StyledModal = styled(Modal)`
  width: 80%;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px;
  }
`
export const StyledHeader = styled.div`
  width: 100%;
  padding: 4px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.metallicSeaweed};

  @media ${({ theme }) => theme.device.sm} {
    font-size: 20px;
  }
`

interface ContainerProps {
  direction: 'column' | 'row'
  justify: string
  align: string
  padding: string
  maxWidth: string
  textAlign: string
}

export const Container = styled.div<Partial<ContainerProps>>`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  padding: ${props => props.padding};
`

interface StyledTextProps {
  align: string
  weight: number
}

export const StyledText = styled.span<Partial<StyledTextProps>>`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  text-align: ${props => props.align || 'center'};
  font-weight: ${props => props.weight || 400};

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px;
  }
`

export const StyledTextSmall = styled.span<Partial<StyledTextProps>>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.nero};
  text-align: ${props => props.align || 'center'};
  font-weight: ${props => props.weight || 400};

  @media ${({ theme }) => theme.device.sm} {
    font-size: 16px;
  }
`

export const StyledLogo = styled(Image)`
  width: 275px;
  height: auto;
`

export const StyledCheck = styled(Image).attrs({
  src: check,
})`
  &&& {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`

interface GapProps {
  height: string
}

export const Gap = styled.div<Partial<GapProps>>`
  height: ${props => props.height || '8px'};
`

export const Divider = styled.div`
  width: 90%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
`
