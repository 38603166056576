import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'

export const StyledLink = styled.span`
  color: ${props => props.theme.colors.sapphireBlue};
  margin: 10px;
  cursor: pointer;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StyledInput = styled.input`
  padding: 10px;
  width: ${props => props.width || '100%'};
  max-width: 300px;
  border: 1px solid #222;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 16px;

  ::placeholder {
    color: ${props => props.theme.colors.silver};
  }
  :focus {
    border: 2px solid rgba(0, 100, 100, 0.5);
    border-radius: 10px;
  }
`

export const SuccessMsg = styled.span`
  margin: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${props => props.theme.colors.green};
`

export const ErrorMsg = styled.span`
  color: ${props => props.theme.colors.carnelian};
  margin-bottom: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 10px;
  max-width: 500px;
  align-items: center;
`

export const ModalContent = styled(Modal.Content)`
  &&& {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
`
