import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
// Components
import { SearchMenu } from '../'
import ShoppingCartIcon from './ShoppingCartIcon'
import AddToCartPopup from './AddToCartPopup'
// Context
import { useStoreContext } from '../../context/StoreContext'
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
// Styles
import {
  Wrapper,
  IconContainer,
  IconWrapper,
  Icon,
  QIconWrapper,
} from './Header.styles'
// import { StoreLanguageModal } from '..'
// Images/Icons
import bars from '../../images/main/Bars.png'
import search from '../../images/main/Search.png'
import cart from '../../images/main/Cart.png'
import logo from '../../svg/logoQ.svg'
import autoShip from '../../svg/icSubscription2.svg'
// import globe from '../../images/main/Globe.png'

const Header = ({ showSidebar }) => {
  const [showSearch, setShowSearch] = useState(false)
  // const [showStoreLanguageModal, setShowStoreLanguageModal] = useState(false)
  const {
    userType,
    isAuthenticated,
    isEventSite,
    isVipSite,
    manageReferral: { isEnrollmentComplete },
  } = useAuthContext()
  const { isStoreLoading } = useStoreContext()
  const { cartData, autoShipItems, isPcOfferInCart, isAmbOfferInCart } =
    useCartContext()

  const cartCount = cartData?.items?.reduce(
    (agg, { quantity }) => agg + (quantity || 0),
    0
  )

  const autoShipCount = autoShipItems.reduce(
    (agg, { quantity }) => agg + (quantity || 0),
    0
  )

  const location = useLocation()

  const shouldShowEnrollmentMessage =
    isAuthenticated &&
    isAmbOfferInCart &&
    !isEnrollmentComplete &&
    location.pathname !== '/enrollment'

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  const handleShowSearch = () => {
    if (isStoreLoading) return
    setShowSearch(!showSearch)
  }

  const handleIconClick = uri => {
    const { pathname } = location
    pathname === uri ? navigate(-1) : navigate(`${uri}`)
  }

  return (
    <>
      <Wrapper isVip={isVipSite}>
        <IconWrapper data-qa="menuButton" onClick={showSidebar} width="35px">
          <Icon height="30px" src={bars} />
        </IconWrapper>
        <QIconWrapper data-qa="qIcon" as={Link} to="/">
          <Icon src={logo} />
        </QIconWrapper>
        <IconContainer>
          <IconWrapper data-qa="search" onClick={handleShowSearch} width="30px">
            <Icon src={search} disabled={isStoreLoading} />
          </IconWrapper>
          {/* {!isEventSite && (
            <IconWrapper onClick={() => setShowStoreLanguageModal(true)}>
              <Icon src={globe} />
            </IconWrapper>
          )} */}
          <IconWrapper
            data-qa="cart"
            onClick={() => handleIconClick('/cart')}
            width="32px"
          >
            <ShoppingCartIcon cartCount={cartCount}>
              <Icon src={cart} />
            </ShoppingCartIcon>
          </IconWrapper>
          {showAutoShip ? (
            <IconWrapper
              data-qa="autoShip"
              onClick={() => handleIconClick('/subscriptions')}
              width="35px"
            >
              <ShoppingCartIcon cartCount={autoShipCount} isAutoShip={true}>
                <Icon src={autoShip} />
              </ShoppingCartIcon>
            </IconWrapper>
          ) : null}
        </IconContainer>
        {/* {!isEventSite && (
          <StoreLanguageModal
            open={showStoreLanguageModal}
            setShowStoreLanguageModal={setShowStoreLanguageModal}
          />
        )} */}
      </Wrapper>

      {showSearch ? (
        <Wrapper justify="center">
          <SearchMenu type="link" callback={handleShowSearch} />
        </Wrapper>
      ) : null}

      {shouldShowEnrollmentMessage ? (
        <Wrapper justify="center" style={{ backgroundColor: 'red' }}>
          <Link to="/enrollment">
            <span style={{ color: 'white' }}>
              Click here to complete enrollment
            </span>
          </Link>
        </Wrapper>
      ) : null}

      <AddToCartPopup />
    </>
  )
}

Header.propTypes = {
  showSidebar: PropTypes.func,
}

export default Header
