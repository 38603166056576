import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'
// Context
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Hooks
import useShowProductPrice from '../../hooks/useShowProductPrice'
// Styles
import {
  ButtonsWrapper,
  CardInfoContainer,
  CardWrapper,
  Count,
  MinusButton,
  OutOfStockOverlay,
  PlusButton,
  RemoveButton,
  RotatedText,
  // StyledCheckbox,
  Title,
} from './CartProductCard.styles'
// Utils
import { enrollSkus } from '../../utils/constants'
import { OUT_OF_STOCK } from '../../utils/productHelpers'

const CartProductCard = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateAdd, setUpdateAdd] = useState(false)
  const [updateSub, setUpdateSub] = useState(false)
  // const [autoshipChecked, setAutoshipChecked] = useState(false)
  const [isAutoShipLocation, setIsAutoShipLocation] = useState(false)

  const location = useLocation()

  const {
    prismicData: {
      prismicCartPage: { out_of_stock, remove: removeText },
    },
  } = usePrismic()

  const { product, quantity, uid } = item

  const configOption = item?.configurable_options?.[0] ?? null

  const {
    // autoship,
    image,
    name,
    sku,
    stock_status,
    url_key,
  } = product
  const {
    cartData,
    cartId,
    manageCart,
    addToAutoShip,
    buildCart,
    downgradeAmbassadorRemoveItems,
    downgradeUserRemovePcFee,
    removeFromAutoShip,
  } = useCartContext()

  const { ProductPricing } = useShowProductPrice(product)

  const handleUpdateQuantity = async (increase = true) => {
    increase ? setUpdateAdd(true) : setUpdateSub(true)

    await manageCart
      .updateCartItems(item, increase, cartId, buildCart)
      .then(() => (increase ? setUpdateAdd(false) : setUpdateSub(false)))
  }

  const handleRemoveFromCart = async () => {
    setIsLoading(true)
    if (sku === enrollSkus.pcFee) {
      return downgradeUserRemovePcFee(uid)
    }
    // downgrade magento associate to current type, remove amb fee, arq fee, and myQFitFee
    if (sku === enrollSkus.ambPremiumSku) {
      return await manageCart
        .findIdsOfAmbEnrollmentProducts(cartData?.items)
        .then(({ arqId, myQFitId }) =>
          downgradeAmbassadorRemoveItems(uid, arqId, myQFitId)
        )
    }
    if (sku === enrollSkus.ambBasicSku) {
      return await manageCart
        .findIdsOfAmbEnrollmentProducts(cartData?.items)
        .then(({ arqId, myQFitId }) =>
          downgradeAmbassadorRemoveItems(uid, arqId, myQFitId)
        )
    }
    await manageCart.removeItemFromCart(uid, cartId, buildCart)
  }

  const handleRemoveFromAutoShip = async () => {
    removeFromAutoShip(sku, name)
  }

  const handleUpdateASQuantity = (increase = true) => {
    if (quantity === 1 && !increase) return
    let newQuantity = increase ? 1 : -1
    addToAutoShip(product, newQuantity)
  }

  // const handleAutoshipChecked = () => {
  //   setAutoshipChecked(!autoshipChecked)
  // }

  useEffect(() => {
    setIsAutoShipLocation(location.pathname === '/subscriptions')
  }, [location])

  const isOutOfStock = stock_status === OUT_OF_STOCK

  const buildName = !!configOption
    ? `${name} - ${configOption.value_label}`
    : `${name}`

  const shouldHideAddSubtract = Object.values(enrollSkus).includes(sku)

  const shouldHideRemove =
    sku === enrollSkus.ambPremiumSku ||
    sku === enrollSkus.ambBasicSku ||
    sku === enrollSkus.arq ||
    sku === enrollSkus.myQFit

  return (
    <CardWrapper>
      <OutOfStockOverlay hidden={!isOutOfStock}>
        <RotatedText>{out_of_stock[0].text}</RotatedText>
      </OutOfStockOverlay>
      <Title data-qa={`title-${sku}`}>
        <span>{buildName}</span>
      </Title>
      <Image
        as={Link}
        data-qa={`image-${sku}`}
        size="small"
        src={image.url}
        style={{ paddingTop: '2em' }}
        to={`/products/${url_key}`}
      />
      <CardInfoContainer>
        <ProductPricing isCart={true} quantity={quantity} />
        {shouldHideAddSubtract ? null : (
          <ButtonsWrapper hidden={isOutOfStock}>
            <MinusButton
              loading={updateSub}
              disabled={updateSub || updateAdd}
              onClick={() =>
                uid
                  ? handleUpdateQuantity(false)
                  : handleUpdateASQuantity(false)
              }
              data-qa={`minusButton-${sku}`}
            >
              <span style={{ fontSize: '2em' }}>-</span>
            </MinusButton>
            <Count>{quantity}</Count>
            <PlusButton
              data-qa={`plusButton-${sku}`}
              disabled={updateAdd || updateSub}
              loading={updateAdd}
              onClick={uid ? handleUpdateQuantity : handleUpdateASQuantity}
            >
              <span style={{ fontSize: '2em' }}>+</span>
            </PlusButton>
          </ButtonsWrapper>
        )}
        {shouldHideRemove ? null : (
          <RemoveButton
            loading={isLoading}
            disabled={isLoading}
            onClick={
              isAutoShipLocation
                ? handleRemoveFromAutoShip
                : handleRemoveFromCart
            }
            data-qa={`removeButton-${sku}`}
          >
            {removeText[0].text}
          </RemoveButton>
        )}
      </CardInfoContainer>
    </CardWrapper>
  )
}

export default CartProductCard
