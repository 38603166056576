import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

export const StyledInput = styled.input`
  padding: 10px;
  width: ${props => props.width || '100%'};
  max-width: 500px;
  border: 1px solid #222;
  border-radius: 5px;
  font-size: 16px;

  ::placeholder {
    color: ${props => props.theme.colors.silver};
  }
  :focus {
    border: 2px solid rgba(0, 100, 100, 0.5);
    border-radius: 5px;
  }
`
export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0 10px 0;
`

export const RedeemButton = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.silver} !important;
    margin-left: 1em;
    box-shadow: none;
    padding: 12px 1em !important;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`
