import { gql } from 'graphql-request'

/**
 * ! RETURNS MANY DIFFERENT RESULTS !
 *
 * @returns @example
 * * success BOOL, loginResults ENUM, associate OBJ || null
 * * message STRING
 *
 * true, AMBASSADOR_ARQ_YEARLY_SUCCESS, associate
 * "Success"
 *
 * false, AMBASSADOR_ARQ_YEARLY_FAILED, null
 * "Yearly Ambassador {associate.AssociateId} account has lapsed as of '{associateSubscription.DatePaidThrough}'"
 *
 * true, AMBASSADOR_ARQ_MONTHLY_SUCCESS, associate
 * "Success"
 *
 * false, AMBASSADOR_ARQ_MONTHLY_FAILED, null
 * "Associate had a monthly subscription which has not been kept up to date"
 *
 * true, AMBASSADOR_NO_ARQ_YEARLY_SUCCESS, associate
 * "Success"
 *
 * false, AMBASSADOR_NO_ARQ_YEARLY_FAILED, null
 * "Ambassador {associate.AssociateId} has lapsed as of '{associateSubscription.DatePaidThrough}'."
 *
 * true, PREFERRED_CUSTOMER_SUCCESS, associate
 * "Success"
 *
 * false, PREFERRED_CUSTOMER_FAILURE, null
 * "Failure: LoginArqAmbassador called with {login?.Associate?.AssociateType} [Preferred]."
 *
 * true, PREFERRED_CUSTOMER_RETAIL_SUCCESS, associate
 * "Success: The old Preferred Customer {associate.AssociateId} will be logged in as a Retail Customer"
 *
 * true, RETAIL_SUCCESS, associate
 * "Success"
 *
 * false, RETAIL_FAILURE, null
 * "Failure: LoginArqAmbassador called with {login?.Associate?.AssociateType} [Retail]."
 *
 * false, BAD_STATUS, null
 * "Associate {associate.AssociateId} is of type {associate.AssociateType} and has a status of {associate.AssociateStatus} which cannot log in."
 *
 * false, NO_LOGIN, null
 * "Failure: LoginArqAmbassador called with {login?.Associate?.AssociateType} [!Preferred || !Retail]."
 */
export const LOGIN_ASSOCIATE = gql`
  mutation LoginAssociate {
    loginAssociate {
      message
      success
      loginResults
      associate {
        addressId
        associateId
        areTermsAccepted
        associateStatus
        associateType
        companyName
        dateSignedUp
        displayName
        dS_Username
        dS_UsersRecordNumber
        emailAddress
        employerIdentificationNumberToken
        firstName
        lastName
        legacyAssociateId
        legalFirstName
        legalLastName
        primaryPhoneNumber
        profileImageFileName
        profileUrl
        receiveEmail
        receiveText
        shippingAddressId
        socialSecurityNumberToken
        uniqueEmailAddress
      }
    }
  }
`

// status: "FIRE_BASE_FAILURE", "NOT_FOUND", "NOT_AN_AMBASSADOR", 'CALL_SUPPORT', "SUCCESS", "NOT_ELIGIBLE_AMBASSADOR",
export const DIRECT_SCALE_INFO = gql`
  mutation DirectScaleInfo($ambassadorOnly: Boolean!, $userName: String!) {
    # userName === dS_Username
    directScaleInfo(ambassadorOnly: $ambassadorOnly, userName: $userName) {
      associate {
        associateId
        dS_Username
        emailAddress
        legacyAssociateId
        primaryPhoneNumber
        secondaryPhoneNumber
        uniqueEmailAddress
      }
      status
    }
  }
`

// possible values returned for status: 'VERIFICATION_COMPLETE', 'MESSAGE_SENT', 'FAILURE'
export const LOGIN_VALIDATION_PROCESS = gql`
  mutation LoginValidationProcess(
    $method: ValidationMethod!
    $loginName: String!
    $verificationInfo: String!
  ) {
    loginValidationProcess(
      input: {
        method: $method
        loginName: $loginName
        verificationInfo: $verificationInfo
      }
    ) {
      status
      associate {
        associateId
        legacyAssociateId
      }
    }
  }
`

// possible values returned for status: 'VERIFICATION_COMPLETE', 'CAN_NOT_FIND_TOKEN'
export const CONFIRM_ACCESS_CODE = gql`
  mutation LoginValidationToken($loginName: String!, $accessCode: String!) {
    loginValidationToken(
      input: { loginName: $loginName, accessCode: $accessCode }
    ) {
      status
      associate {
        associateId
        legacyAssociateId
      }
    }
  }
`
