import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'
// Services
import { auth } from '../services/firebase'
import { QService } from '../services/q-services'
import { Magento } from '../services/magento'
// Types
import {
  AuthContextTypes,
  FirebaseLogin,
  InitAuthState,
  InitUserState,
  QUser,
} from '../types/contexts/AuthContextTypes'
import { CreditCard } from '../types/PaymentsTypes'
// Utils
import {
  getMagentoUser,
  getUplineUser,
  loginWithMagento,
} from '../utils/loginHelpers'
import { initialAuthState, initialUserState } from './initialStates'

const MDEFAULTCARD = 'mdefaultcard'

const AuthContext = React.createContext<Partial<AuthContextTypes>>({})

export const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [
    {
      isAuthenticated,
      isAuthLoading,
      isEventSite,
      isFirstLogin,
      isVerified,
      isVipSite,
    },
    setAuthState,
  ] = useState<InitAuthState>(initialAuthState)

  const [
    {
      cardsOnFile,
      isEnrollmentComplete,
      isReferral,
      magentoUser,
      qUser,
      qUserUpline,
      referralData,
      shouldAddPcFee,
      userType,
    },
    setUserState,
  ] = useState<Partial<InitUserState>>(initialUserState)

  const providers = {
    apple: new OAuthProvider('apple'),
    facebook: new FacebookAuthProvider(),
    google: new GoogleAuthProvider(),
  }

  const handleFirebaseLogin = async ({
    email,
    password,
    isSignUp,
    handleError,
  }: FirebaseLogin) => {
    setAuthState(prev => ({ ...prev, isAuthLoading: true }))

    if (isSignUp) {
      return await createUserWithEmailAndPassword(auth, email, password).catch(
        ({ message }) => {
          handleError(message)
          setAuthState(prev => ({ ...prev, isAuthLoading: false }))
        }
      )
    }

    return await signInWithEmailAndPassword(auth, email, password).catch(
      ({ message }) => {
        handleError(message)
        setAuthState(prev => ({ ...prev, isAuthLoading: false }))
      }
    )
  }

  const firebaseLoginWithProvider = (
    service: 'facebook' | 'apple' | 'google'
  ): Promise<void> => {
    setAuthState(prev => ({ ...prev, isAuthLoading: true }))
    const provider = providers[`${service}`]
    if (service === 'apple') {
      provider.addScope('name')
      provider.addScope('email')
    } else {
      provider.addScope(service === 'google' ? 'profile' : 'public_profile')
      provider.addScope('email')
    }
    return signInWithRedirect(auth, provider)
  }

  const handleUserLogout = async () => {
    // localStorage.clear()
    localStorage.removeItem('guestCartId')
    localStorage.removeItem('autoShipState')
    localStorage.removeItem('mdefaultcard')
    Magento.User.unSetToken()
    QService.unsetToken()
    await signOut(auth).then(() => {
      setUserState({ ...initialUserState })
      setAuthState({ ...initialAuthState })
      handleRefreshSite()
    })
  }

  const resetPassword = (email: string): Promise<void> =>
    sendPasswordResetEmail(auth, email)

  const setIsVerified = () =>
    setAuthState(prev => ({ ...prev, isVerified: true, isFirstLogin: true }))

  const setFirebaseToken = () => {
    const user = auth.currentUser.toJSON()
    const token = user.stsTokenManager.accessToken
    QService.setToken(token)
    return token
  }

  const handleRefreshSite = () =>
    setAuthState(prev => ({
      ...prev,
      isFirstLogin: !isFirstLogin,
    }))

  const updateMagentoUser = async () => {
    let magentoUser = await getMagentoUser()
    setUserState(prev => ({ ...prev, magentoUser }))
  }

  const updateQUser = async (data: Partial<QUser>) =>
    await QService.User.updateAssociate(data).then(updatedUserData =>
      setUserState(prev => ({
        ...prev,
        qUser: updatedUserData,
        isReferral: false,
      }))
    )

  const updateQUserFromAmbEnrollmentForm = async (data: Partial<QUser>) =>
    await QService.User.updateAssociateInAmbassadorEnrollment(data).then(
      updatedUserData =>
        setUserState(prev => ({
          ...prev,
          isEnrollmentComplete: true,
          qUser: updatedUserData,
        }))
    )

  const handleAddNewUser = async (userInfo: {
    firstName: string
    lastName: string
  }) => {
    const displayName = `${userInfo.firstName} ${userInfo.lastName}`
    let newQUserData = {
      country: 'us',
      displayName,
      firstName: userInfo.firstName,
      languageCode: 'en',
      lastName: userInfo.lastName,
      enrollerSlug: referralData?.store,
    }

    await QService.User.addNewQUser(newQUserData).then(() =>
      setAuthState(prev => ({
        ...prev,
        isFirstLogin: true,
      }))
    )
  }

  const getExistingEnrollment = () => {
    const enrollData = localStorage.getItem('enrollerData')
    if (!enrollData) return

    const enrollerData = JSON.parse(enrollData)
    handleSetUserState({
      isReferral: true,
      referralData: enrollerData,
      shouldAddPcFee: enrollerData.type === 'pc',
    })
  }

  const manageReferral = {
    isEnrollmentComplete,
    isReferral,
    referralData,
  }

  const addCardToFile = async (card: CreditCard, saveToFile: boolean) => {
    updateSelectedCard(card)
    if (saveToFile)
      await QService.Payments.saveCardData(qUser.associateId, card)
        .then(() => toast.success('New Card Added'))
        .then(
          async () =>
            await QService.Payments.getCardsOnFile(qUser.associateId).then(
              cards => setUserState(prev => ({ ...prev, cardsOnFile: cards }))
            )
        )
  }

  const getSelectedCard = () => {
    try {
      const defaultCard =
        typeof window !== 'undefined' && localStorage.getItem(MDEFAULTCARD)
      return defaultCard ? JSON.parse(defaultCard) : cardsOnFile[0] || {}
    } catch (error) {
      return {}
    }
  }

  const updateSelectedCard = (selectedCard: CreditCard) => {
    localStorage.setItem(MDEFAULTCARD, JSON.stringify(selectedCard))
  }

  // THIS IS A TEMP FIX FOR THE DUPLICATE CC BUG
  const removeDuplicates = (cards: CreditCard[]) => {
    function uniqByKeepLast(a, key) {
      return [...new Map(a.map(x => [key(x), x])).values()]
    }
    return uniqByKeepLast(cards, card => card.creditCardGuid)
  }

  const refreshAuthState = async () => {
    const firebaseToken = setFirebaseToken()
    await QService.Auth.loginAssociate()
    await loginWithMagento({ ...qUser, firebaseToken })
  }

  const handleSetUserState = (userState: Partial<InitUserState>) =>
    setUserState(prev => ({ ...prev, ...userState }))

  const handleSetAuthState = (authState: Partial<InitAuthState>) =>
    setAuthState(prev => ({ ...prev, ...authState }))

  useEffect(() => {
    if (isAuthLoading) return
    if (qUser) {
      // REMOVE EXISTING ENROLLER DATA ONCE USER HAS CREATED ACCOUNT
      localStorage.removeItem('enrollerData')
    } else {
      getExistingEnrollment()
    }
  }, [isAuthLoading, qUser])

  useEffect(() => {
    setAuthState(prev => ({ ...prev, isAuthLoading: true }))
    getRedirectResult(auth).catch(err => {
      if (err?.code === 'auth/web-storage-unsupported') {
        toast.error(
          'Allow third-party cookies in your browser for this site to login using google or facebook',
          { duration: 5000 }
        )
      }
    })
    return onAuthStateChanged(auth, async user => {
      if (user) {
        // SET FIREBASE TOKEN IN AUTH HEADER FOR ALL SUBSEQUENT QSERVICE CALLS
        const firebaseToken = setFirebaseToken()

        // USING FIREBASE TOKEN, ATTEMPT TO LOGIN TO QS
        let {
          // message,
          success,
          loginResults,
          associate: qUser,
        } = await QService.Auth.loginAssociate()

        if (success) {
          // LOG USER IN TO MAGENTO, GET/SET TOKEN, RETURN USER DATA
          let magentoUser = await loginWithMagento({
            ...qUser,
            firebaseToken,
          })
            .then(({ magentoUser }) => magentoUser)
            .catch(err => {
              console.log(err)
            })

          if (!magentoUser) {
            setAuthState(prev => ({
              ...prev,
              isAuthLoading: false,
            }))
            toast.error('Error logging in to Magento')
            return
          }
          await QService.User.setCustomerGroup(qUser.associateType)

          let cardsOnFile = await QService.Payments.getCardsOnFile(
            qUser.associateId
          )

          // ADD UPLINE USER'S DATA TO LOGGED IN USER BEFORE SETTING STATE
          let { uplineUserData, associateSlug } = await getUplineUser()

          handleSetUserState({
            magentoUser,
            isEnrollmentComplete: !!(
              qUser?.employerIdentificationNumberToken ||
              qUser?.socialSecurityNumberToken
            ),
            userType: qUser?.associateType,
            qUser: { ...qUser, associateSlug },
            qUserUpline: uplineUserData,
            cardsOnFile: removeDuplicates(cardsOnFile),
          })

          setAuthState(prev => ({
            ...prev,
            isAuthLoading: false,
            isVerified: true,
            isAuthenticated: true,
          }))
        } else {
          // ! Handle other login results here...
          if (loginResults === 'NO_LOGIN') {
            setAuthState(prev => ({
              ...prev,
              isAuthLoading: false,
              isVerified: false,
              isAuthenticated: true,
            }))
            navigate('/login')
          }
          setAuthState(prev => ({
            ...prev,
            isAuthLoading: false,
          }))
        }
      } else {
        setAuthState(prev => ({
          ...prev,
          isAuthLoading: false,
        }))
      }
    })
  }, [isFirstLogin])

  return (
    <AuthContext.Provider
      value={{
        cardsOnFile,
        isAuthenticated,
        isAuthLoading,
        isEventSite,
        isFirstLogin,
        isVerified,
        isVipSite,
        magentoUser,
        manageReferral,
        qUser,
        qUserUpline,
        shouldAddPcFee,
        userType,
        addCardToFile,
        firebaseLoginWithProvider,
        getSelectedCard,
        handleAddNewUser,
        handleFirebaseLogin,
        handleRefreshSite,
        handleSetAuthState,
        handleSetUserState,
        handleUserLogout,
        refreshAuthState,
        resetPassword,
        setIsVerified,
        updateMagentoUser,
        updateQUser,
        updateQUserFromAmbEnrollmentForm,
        updateSelectedCard,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

// -------------------- EXAMPLE SHARED URLS --------------------
// http://localhost:8000/store/innovation1?type=pc&firstname=Daryl&lastname=McStoog
// http://localhost:8000?store/innovation1?type=retail&firstname=Daryl&lastname=McStoog
// http://localhost:8000/store/innovation1?type=ambassador&firstname=Daryl&lastname=McStoog
// http://192.168.1.144:8000/store/innovation1?type=pc&firstname=Daryl&lastname=McStoog
// http://192.168.1.144:8000/store/innovation1?type=retail&firstname=Daryl&lastname=McStoog
// http://192.168.1.144:8000/store/innovation1?type=ambassador&firstname=Daryl&lastname=McStoog

// -------------------- WITH URI ENCODING --------------------
// http://localhost:8000/store/innovation1?type=pc%26firstname=Daryl%26lastname=McStoog
// http://localhost:8000/store/innovation1?type=retail%26firstname=Daryl%26lastname=McStoog
// http://localhost:8000/store/innovation1?type=ambassador%26firstname=Daryl%26lastname=McStoog
// http://192.168.1.144:8000/store/innovation1?type=pc%26firstname=Daryl%26lastname=McStoog
// http://192.168.1.144:8000/store/innovation1?type=retail%26firstname=Daryl%26lastname=McStoog
// http://192.168.1.144:8000/store/innovation1?type=ambassador%26firstname=Daryl%26lastname=McStoog

// -------- NOTE - type of ambassador sends the user to /login route -------------
