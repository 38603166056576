import toast from 'react-hot-toast'
import axios from 'axios'
import StackTrace from 'stacktrace-js'
import { Logger } from './logger'
// Services & Utils
import { Magento } from '../services/magento'
import { QService } from '../services/q-services'
import environment from '../utils/environment'
import { enrollSkus } from './constants'
// Types
import { OrderDetailsType, CartItem } from '../types/CartTypes'

export const getOrderDetailValues = ({
  subtotal_excluding_tax,
  subtotal_including_tax,
  grand_total,
  shipping,
}: OrderDetailsType) => {
  const subTotalValue = subtotal_excluding_tax?.value || 0
  const taxValue =
    subtotal_including_tax?.value - subtotal_excluding_tax?.value || 0
  const shippingValue = shipping?.amount?.value || 0
  const grandTotalValue = grand_total?.value || 0

  const getAmounts = () => ({
    subTotalText: subTotalValue.toFixed(2) ?? '',
    taxesText: taxValue.toFixed(2) ?? '',
    shippingText: shippingValue.toFixed(2) ?? '',
  })

  const getTotalWithShipping = () => {
    return (grandTotalValue + shippingValue).toFixed(2)
  }

  return {
    getAmounts,
    getTotalWithShipping,
  }
}

const SLACK = environment.SLACK_WEBHOOK_URL

const addItemToCart = async (
  item,
  quantity = 1,
  cartId: string,
  updateCart
) => {
  const itemObj = {
    sku: item.sku,
    quantity,
  }

  const cartObj = {
    cartId: cartId,
    cartItems: [itemObj],
  }

  await Magento.Cart.addProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error adding item')
    })
}

const addItemsToCart = async (
  items: string[],
  quantity = 1,
  cartId: string,
  updateCart
) => {
  const cartItems = items.map(sku => ({
    sku,
    quantity,
  }))

  const cartObj = {
    cartId: cartId,
    cartItems,
  }

  await Magento.Cart.addProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error adding items')
    })
}

const addBundleProductToCart = async (
  product,
  quantity = 1,
  cartId,
  updateCart
) => {
  const bundledOptions = product.items.map(item => ({
    id: item.option_id,
    quantity: item.options[0].quantity,
    value: [`${item.options[0].id}`],
  }))

  const productObj = {
    data: {
      sku: product.sku,
      quantity,
    },
    bundle_options: bundledOptions,
  }

  const cartObj = {
    cart_id: cartId,
    cart_items: [productObj],
  }

  await Magento.Cart.addBundleProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? 'Error adding bundle item'
      )
    })
}

const addConfigurableProductToCart = async (
  item,
  quantity = 1,
  cartId,
  updateCart
) => {
  const itemObj = {
    sku: item.sku,
    selected_options: [item.optionUid],
    quantity,
  }

  const cartObj = {
    cartId: cartId,
    cartItems: [itemObj],
  }

  await Magento.Cart.addProductsToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(
        err?.response?.errors[0]?.message ?? 'Error adding configurable item'
      )
    })
}

const removeItemFromCart = async (
  itemUid: string,
  cartId: string,
  updateCart: any
) => {
  const cartObj = {
    cart_id: cartId,
    cart_item_uid: itemUid,
  }

  await Magento.Cart.removeItemFromCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error removing item')
    })
}

const removeItemsFromCart = async (
  items: string[],
  cartId: string,
  updateCart: any
) => {
  const cartItems = items.map(uid => ({
    cart_item_uid: uid,
    quantity: 0,
  }))

  const cartObj = {
    cart_id: cartId,
    cart_items: cartItems,
  }

  await Magento.Cart.updateItemsInCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error removing items')
    })
}

const updateCartItems = async (
  item,
  add: boolean = true,
  cartId: string,
  updateCart: any
) => {
  const { uid, quantity } = item
  const itemObj = {
    cart_item_uid: uid,
    quantity: add ? quantity + 1 : quantity - 1,
  }
  const cartObj = {
    cart_id: cartId,
    cart_items: [itemObj],
  }

  await Magento.Cart.updateItemsInCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error updating items')
    })
}

const handleReorderItems = async (orderNumber: string, updateCart: any) =>
  await Magento.Cart.reorderItems({ orderNumber })
    .then(updateCart)
    .catch(err => Logger.log(err))

const addCouponToCart = async (
  code: string,
  cartId: string,
  updateCart: any
) => {
  const cartObj = {
    cart_id: cartId,
    coupon_code: code,
  }
  return await Magento.Cart.applyCouponToCart(cartObj)
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error reordering items')
    })
}

const removeCouponFromCart = async (cartId: string, updateCart: any) =>
  await Magento.Cart.removeCouponFromCart({
    cart_id: cartId,
  })
    .then(updateCart)
    .catch(err => {
      console.log(err)
      toast.error(err?.response?.errors[0]?.message ?? 'Error adding coupon')
    })

const placeOrder = async (
  isLoading,
  cartData,
  getSelectedCard,
  updateCart,
  refreshCart,
  isPaypalMethod,
  payPalOrderId
) => {
  isLoading(true)
  try {
    // setShippingMethodOnCart
    const method = cartData?.shipping_addresses[0].available_shipping_methods
    if (method.length) {
      const shippingMethodObj = {
        cart_id: cartData.id,
        shipping_methods: [
          {
            carrier_code: method[0].carrier_code,
            method_code: method[0].method_code,
          },
        ],
      }
      await Magento.Cart.setShippingMethodOnCart(shippingMethodObj)
        .then(updateCart)
        .then(() => Logger.log('shipping method set'))
        .catch(err => Logger.log(err))
    }

    // setPaymentMethodOnCart
    const cartPaymentMethods = cartData?.available_payment_methods[0]
    if (cartPaymentMethods) {
      const { code } = cartPaymentMethods
      const paymentMethodObj = {
        cart_id: cartData.id,
        payment_method: {
          code,
        },
      }
      await Magento.Cart.setPaymentMethodOnCart(paymentMethodObj)
        .then(updateCart)
        .then(() => Logger.log('payment method set'))
        .catch(err => Logger.log(err))
    }
  } catch (error) {
    Logger.log(error)
  }

  const { placeOrder } = await Magento.Cart.placeOrder({
    cart_id: cartData.id,
  })

  let orderNumber: string, status: string, error: any

  const magentoOrderId = placeOrder.order.order_number
  orderNumber = magentoOrderId
  let ordersArr = await Magento.User.getCustomerOrders().then(
    ({ customerOrders }) => customerOrders.items
  )
  let orderInfo = ordersArr.find(order => order.order_number === magentoOrderId)
  status = orderInfo.status
  // TODO - Import this function from Store Context
  const { creditCardGuid } = getSelectedCard()
  const nexioObj = {
    magentoOrderId: orderInfo.id,
    creditCardGuid,
  }

  if (!isPaypalMethod) {
    // handle payment response from Nexio & Q Services
    try {
      let { value } = await QService.Payments.nexioPostTransaction(nexioObj)
      if (value !== 'Success') {
        error = JSON.parse(value.replace('\u0022', '"'))
      }
    } catch (err) {
      StackTrace.get(err)
        .then(data => {
          const stackErr = JSON.stringify(data)
          const qErr = JSON.stringify(err)
          return axios.post(
            SLACK,
            {
              text: `=======ORDER FAILED, STATUS PENDING=======\n\nStack Error:\n${stackErr}\n\nQ Services Error:\n${qErr}`,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
        })
        .catch(err => console.log(err))

      error = true
    } finally {
      localStorage.removeItem('mdefaultcard')
    }
  } else {
    // handle paypal method
    const payPalObj = {
      magentoOrderId: orderInfo.id,
      payPalOrderId,
    }
    try {
      let value = await QService.Payments.processPayPalOrder(payPalObj)
      if (value !== true) {
        error = true
      }
    } catch (err) {
      StackTrace.get(err)
        .then(data => {
          const stackErr = JSON.stringify(data)
          const qErr = JSON.stringify(err)
          return axios.post(
            SLACK,
            {
              text: `=======PAYPAL ORDER ERROR=======\n\nStack Error:\n${stackErr}\n\nQ Services Error:\n${qErr}`,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
        })
        .catch(err => console.log(err))

      error = true
    }
  }

  refreshCart()
  isLoading(false)

  return { orderNumber, error, status }
}

export const findIdsOfAmbEnrollmentProducts = async (items: CartItem[]) => {
  const { arq, myQFit } = enrollSkus
  const arqArray = items.filter(({ product }) => product.sku === arq)
  const myQFitArray = items.filter(({ product }) => product.sku === myQFit)
  return { arqId: arqArray?.[0]?.uid, myQFitId: myQFitArray?.[0]?.uid }
}

const doesCartHaveCBD = cartData =>
  cartData?.items?.some(item => item.product.iCBD)

const manageCart = {
  addItemToCart,
  addItemsToCart,
  addBundleProductToCart,
  addConfigurableProductToCart,
  addCouponToCart,
  handleReorderItems,
  removeCouponFromCart,
  removeItemFromCart,
  removeItemsFromCart,
  updateCartItems,
  placeOrder,
  findIdsOfAmbEnrollmentProducts,
  doesCartHaveCBD,
}

export default manageCart
