import { gql } from 'graphql-request'

export const GET_CARDS_ON_FILE = gql`
  mutation GetCardsOnFile($associateId: Int!) {
    nexioCreditCardsForAssociate(associateId: $associateId) {
      associateId
      associatePaymentInformationId
      dateCreated
      dateModified
      companyName
      creditCardGuid
      creditCardType
      lastFourDigits
      expirationDate
      active
    }
  }
`

export const GET_ONE_TIME_NEXIO_TOKEN = gql`
  mutation OneTimeToken {
    nexioOneTimeTokenIframe {
      token
    }
  }
`

export const SAVE_CARD_TO_ASSOCIATE = gql`
  mutation SaveCardData($associateId: Int!, $cardData: SaveCardDataInInput!) {
    nexioSaveCardData(associateId: $associateId, cardData: $cardData) {
      value
    }
  }
`

export const NEXIO_POST_TRANSACTION = gql`
  mutation NexioPostTransaction(
    $magentoOrderId: String!
    $creditCardGuid: String!
  ) {
    nexioPostTransaction(
      magentoOrderId: $magentoOrderId
      creditCardGuid: $creditCardGuid
    ) {
      value
    }
  }
`

export const PROCESS_PAYPAL_ORDER = gql`
  mutation ProcessPayPalOrder(
    $magentoOrderId: String!
    $payPalOrderId: String!
  ) {
    processPayPalOrder(
      input: { magentoOrderId: $magentoOrderId, payPalOrderId: $payPalOrderId }
    ) {
      success
    }
  }
`
