import * as yup from 'yup'

// ------------------------ User Authorization ------------------------

export const signUpSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(/[a-z]/, 'Must contain at least 1 lowercase letter')
    .matches(/[A-Z]/, 'Must contain at lease 1 uppercase letter')
    .matches(/\d/, 'Must contain at least 1 digit')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirm: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
})

export const forgotPasswordSchema = yup.object().shape({
  resetEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
})

export const accountEnrollmentSchema = yup.object().shape({
  referred_by: yup.string().max(30, 'Max 30 chars'),
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  telephone: yup
    .string()
    .required('Phone number is required')
    .length(10, '10 digit number required')
    .matches(/\d{10}/, 'Invalid entry'),
})

// ------------------------ Q Services Account Validation ------------------------

export const existingCustomer = yup.object().shape({
  customer: yup.string().required('Customer ID or Username is required'),
})

export const newCustomer = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
})

export const enterCode = yup.object().shape({
  code: yup
    .string()
    .required('Code is required')
    .matches(/^[0-9]{6}/, 'Invalid code, please try again'),
})

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
  email: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
  phone: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
})

// ------------------------ Checkout ------------------------

export const shippingAddressSchema = yup.object().shape({
  firstname: yup.string().required('First name required').max(25, 'Too long'),
  lastname: yup.string().required('Last name required').max(25, 'Too long'),
  address1: yup.string().required('Address1 required').max(30, 'Too long'),
  address2: yup.string().max(30, 'Too long'),
  city: yup.string().required('City required').max(30, 'Too long'),
  state: yup.string().required('State required'),
  postcode: yup
    .string()
    .required('Zip code required')
    .matches(/^[0-9]{5}/, '5 digit zip code')
    .length(5, '5 digit zip code'),
  country_code: yup
    .string()
    .required()
    .matches(/^[A-Z]{2}/),
  telephone: yup
    .string()
    .required('Phone number required')
    .matches(/^[0-9]{10}/, '10 digit format (no dashes/parenthesis)')
    .length(10, '10 digit format (no dashes/parenthesis)'),
})

export const addCouponCode = yup.object().shape({
  code: yup.string().max(20, 'Too long'),
})

export const enrollmentSchema = yup.object().shape({
  identification: yup.string().required('Identification option is required'),
  ssnFirst: yup
    .string()
    .test(
      'oneOfRequired',
      '3 digit number required in first field',
      function () {
        if (this.parent.identification !== 'personal') return true
        if (!/^[0-9]{3}/.test(this.parent.ssnFirst)) return false
        return this.parent.ssnFirst
      }
    ),
  ssnMiddle: yup
    .string()
    .test(
      'oneOfRequired',
      '2 digit number required in second field',
      function () {
        if (this.parent.identification !== 'personal') return true
        if (!/^[0-9]{2}/.test(this.parent.ssnMiddle)) return false
        return this.parent.ssnMiddle
      }
    ),
  ssnLast: yup
    .string()
    .test(
      'oneOfRequired',
      '4 digit number required in last field',
      function () {
        if (this.parent.identification !== 'personal') return true
        if (!/^[0-9]{4}/.test(this.parent.ssnLast)) return false
        return this.parent.ssnLast
      }
    ),
  einFirst: yup
    .string()
    .test(
      'oneOfRequired',
      '2 digit number required in first field',
      function () {
        if (this.parent.identification === 'personal') return true
        if (!/^[0-9]{2}/.test(this.parent.einFirst)) return false
        return this.parent.einFirst
      }
    ),
  einLast: yup
    .string()
    .test(
      'oneOfRequired',
      '7 digit number required in last field',
      function () {
        if (this.parent.identification === 'personal') return true
        if (!/^[0-9]{7}/.test(this.parent.einLast)) {
          return false
        }
        return this.parent.einLast
      }
    ),
  companyName: yup
    .string()
    .test('oneOfRequired', 'Company Name required', function () {
      if (this.parent.identification !== 'business') {
        return true
      }
      return this.parent.companyName
    }),
  legalFirstName: yup.string().required('First name is required'),
  legalLastName: yup.string().required('Last name is required'),
  primaryPhoneNumber: yup
    .string()
    .required('Phone number is required')
    .length(10, '10 digit number required')
    .matches(/\d{10}/, 'Invalid entry'),
  emailAddress: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  dateBirth: yup
    .string()
    .required('Birthday is required')
    .matches(
      /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/,
      'Must be formatted MM/DD/YYYY'
    ),
  address1: yup
    .string()
    .required('Address1 required')
    .max(30, 'Must not exceed 30 characters'),
  address2: yup.string().max(30, 'Must not exceed 30 characters'),
  city: yup.string().required('City required').max(30, 'Too long'),
  state: yup.string().required('State required'),
  zip: yup
    .string()
    .required('Zip code required')
    .matches(/^[0-9]{5}/, '5 digit zip code')
    .length(5, '5 digit zip code'),
  slug: yup
    .string()
    .required('This field is required')
    .matches(
      /^[a-z0-9]+$/,
      'Must consist of only lowercase letters and numbers'
    )
    .min(3, 'Must be at least 3 characters')
    .max(60, 'Must not exceed 60 characters'),
  send1099FormElectronically: yup
    .boolean()
    .oneOf([true], 'Electronic 1099 required'),
  areTermsAccepted: yup
    .boolean()
    .oneOf([true], 'Must agree to terms and conditions'),
})
