import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import environment from '../../utils/environment'

export const StyledButton = styled(Button)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: none;
    padding: 0.5em 0;
    margin: 1em 0 1.5em 0;
    width: 90%;
    max-width: 300px;
    border-radius: 5px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.7);
    text-transform: uppercase;

    :active {
      box-shadow: none;
      border: none;
    }

    @media ${({ theme }) => theme.device.sm} {
      :hover {
        box-shadow: none;
        border: none;
      }
    }
  }
`

export const PrimaryButton = styled(StyledButton)`
  &&& {
    background-color: ${({ theme }) =>
      environment.IS_VIP_SITE
        ? theme.colors.vipBlue
        : theme.colors.metallicSeaweed};
    color: ${props => props.theme.colors.white};
  }
`

export const BasicPrimaryButton = styled(StyledButton)`
  &&& {
    color: ${({ theme }) =>
      environment.IS_VIP_SITE
        ? theme.colors.vipBlue
        : theme.colors.metallicSeaweed};
    background-color: inherit;
  }
`

export const WarningButton = styled(StyledButton)`
  &&& {
    background-color: ${({ theme }) => theme.colors.skyBlue};
    color: ${({ theme }) => theme.colors.white};
    width: 150px;
    margin-top: 0;
  }
`

const AddSubButton = styled(Button)`
  &&& {
    border-radius: 0px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    flex: 1 0 40%;
    margin: 0;
    padding: 0.5em;
  }
`

export const AddButton = styled(AddSubButton)`
  &&& {
    justify-content: flex-start;
  }
`

export const SubtractButton = styled(AddSubButton)`
  &&& {
    justify-content: flex-end;
  }
`

export const Count = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: bolder;
  font-size: 1em;
`

export const AddSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`
