import { gql } from 'graphql-request'

export const GET_CATEGORIES = gql`
  query {
    categories {
      items {
        uid
        name
        children {
          uid
          name
          image
          url_key
          url_path
          children {
            uid
            name
            image
            url_key
            url_path
          }
        }
      }
    }
  }
`

export const GET_ALL_PRODUCTS = gql`
  query {
    products(search: "", pageSize: 400) {
      items {
        uid
        name
        url_key
        url_path
        sku
        meta_title
        meta_description
        meta_keyword
        autoship
        ds_itemid
        iCBD
        categories {
          uid
          image
          url_key
          url_path
          name
          breadcrumbs {
            category_uid
            category_name
            category_url_key
          }
        }
        ... on BundleProduct {
          items {
            option_id
            uid
            required
            options {
              id
              quantity
              qty
              price
            }
          }
        }
        ... on ConfigurableProduct {
          variants {
            attributes {
              uid
              label
              code
            }
          }
          media_gallery {
            url
            label
            position
          }
          configurable_options {
            uid
            attribute_uid
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              uid
            }
            product_id
          }
          configurable_product_options_selection(
            configurableOptionValueUids: []
          ) {
            options_available_for_selection {
              attribute_code
              option_value_uids
            }
            media_gallery {
              url
              label
              position
            }
            variant {
              uid
              sku
              name
            }
          }
        }
        description {
          html
        }
        short_description {
          html
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        price_range {
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
          minimum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
        stock_status
        pv
        wholesale_price
        image {
          url
        }
        ingredient
        suggested_use
        media_gallery {
          url
          label
          position
        }
        reviews {
          items {
            created_at
            text
            nickname
            summary
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
      }
    }
  }
`

export const GET_SINGLE_CATEGORY_PRODUCTS = gql`
  query getSingleCategoryProducts($url_key: String) {
    categories(filters: { url_key: { eq: $url_key } }) {
      ...singleCategoryFragment
    }
  }

  fragment singleCategoryFragment on CategoryResult {
    items {
      uid
      name
      image
      url_key
      url_path
      products(pageSize: 50) {
        ...categoryProductFragment
      }
    }
  }

  fragment categoryProductFragment on CategoryProducts {
    items {
      uid
      name
      url_key
      url_path
      sku
      meta_title
      meta_description
      meta_keyword
      autoship
      ds_itemid
      iCBD
      categories {
        name
      }
      ... on BundleProduct {
        items {
          option_id
          uid
          required
          options {
            id
            quantity
            qty
            price
          }
        }
      }
      ... on ConfigurableProduct {
        variants {
          attributes {
            uid
            label
            code
          }
        }
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
          variant {
            uid
            sku
            name
          }
        }
      }
      description {
        html
      }
      short_description {
        html
      }
      price {
        regularPrice {
          amount {
            value
          }
        }
      }
      price_range {
        maximum_price {
          final_price {
            value
          }
          regular_price {
            value
          }
        }
      }
      pv
      wholesale_price
      image {
        url
      }
      media_gallery {
        url
        label
        position
      }
      reviews {
        items {
          created_at
          text
          nickname
          summary
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
    }
  }
`

export const GET_SINGLE_PRODUCT = gql`
  query getSingleProductByUrlKey($url_key: String) {
    products(filter: { url_key: { match: $url_key } }) {
      items {
        uid
        name
        url_key
        url_path
        sku
        meta_title
        meta_description
        meta_keyword
        autoship
        ds_itemid
        iCBD
        categories {
          name
        }
        ... on BundleProduct {
          items {
            option_id
            uid
            required
            options {
              id
              quantity
              qty
              price
            }
          }
        }
        ... on ConfigurableProduct {
          variants {
            attributes {
              uid
              label
              code
            }
          }
          media_gallery {
            url
            label
            position
          }
          configurable_options {
            uid
            attribute_uid
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              uid
            }
            product_id
          }
          configurable_product_options_selection(
            configurableOptionValueUids: []
          ) {
            options_available_for_selection {
              attribute_code
              option_value_uids
            }
            media_gallery {
              url
              label
              position
            }
            variant {
              uid
              sku
              name
            }
          }
        }
        description {
          html
        }
        short_description {
          html
        }
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
        price_range {
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
        pv
        wholesale_price
        image {
          url
        }
        media_gallery {
          url
          label
          position
        }
        reviews {
          items {
            created_at
            text
            nickname
            summary
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
      }
      filters {
        name
        filter_items_count
        request_var
      }
    }
  }
`

export const GET_SINGLE_PRODUCT_BY_SKU = gql`
  query getSingleProductByUrlKey($sku: String) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        uid
        name
        url_key
        url_path
        sku
        price {
          regularPrice {
            amount {
              value
            }
          }
        }
      }
    }
  }
`

export const GET_LANDING_PAGE_STORE_DATA = gql`
  query getLandingPageData {
    benefit: categories(filters: { url_key: { eq: "benefit" } }) {
      ...parentCategoryFragment
    }
    type: categories(filters: { url_key: { eq: "type" } }) {
      ...parentCategoryFragment
    }
    featuredProducts: categories(
      filters: { url_key: { eq: "featured-products" } }
    ) {
      ...singleCategoryFragment
    }
  }

  fragment parentCategoryFragment on CategoryResult {
    items {
      uid
      name
      url_key
      url_path
      ...categoryChildrenFragment
    }
  }

  fragment singleCategoryFragment on CategoryResult {
    items {
      uid
      name
      url_key
      url_path
      products(pageSize: 20) {
        ...categoryProductFragment
      }
    }
  }

  fragment categoryChildrenFragment on CategoryTree {
    children {
      uid
      name
      url_key
      url_path
      image
    }
  }

  fragment categoryProductFragment on CategoryProducts {
    items {
      uid
      name
      url_key
      url_path
      sku
      meta_title
      meta_description
      meta_keyword
      autoship
      ds_itemid
      iCBD
      stock_status
      categories {
        name
      }
      ... on BundleProduct {
        items {
          option_id
          uid
          required
          options {
            id
            quantity
            qty
            price
          }
        }
      }
      ... on ConfigurableProduct {
        variants {
          attributes {
            uid
            label
            code
          }
        }
        media_gallery {
          url
          label
          position
        }
        configurable_options {
          uid
          attribute_uid
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            uid
          }
          product_id
        }
        configurable_product_options_selection(
          configurableOptionValueUids: []
        ) {
          options_available_for_selection {
            attribute_code
            option_value_uids
          }
          media_gallery {
            url
            label
            position
          }
          variant {
            uid
            sku
            name
          }
        }
      }
      description {
        html
      }
      short_description {
        html
      }
      price {
        regularPrice {
          amount {
            value
          }
        }
      }
      price_range {
        maximum_price {
          final_price {
            value
          }
          regular_price {
            value
          }
        }
      }
      pv
      wholesale_price
      image {
        url
      }
      media_gallery {
        url
        label
        position
      }
      reviews {
        items {
          created_at
          text
          nickname
          summary
          average_rating
          ratings_breakdown {
            name
            value
          }
        }
      }
    }
  }
`

export const GET_AVAILABLE_COUNTRIES = gql`
  query {
    countries {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`

export const GET_COUNTRY_DATA_BY_ID = gql`
  query getCountryData($id: String!) {
    country(id: $id) {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`
