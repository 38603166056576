import { gql } from 'graphql-request'

export const GET_USER_TREE_NODE = gql`
  query TreeNodeFor($legacyAssociateId: Int!) {
    treeNodeFor(legacyAssociateId: $legacyAssociateId) {
      associate {
        associateId
        associateSlugs {
          slug
        }
      }
      uplineTreeNode {
        associate {
          firstName
          lastName
          displayName
        }
      }
      uplineAssociateId
    }
  }
`

export const GET_TREE_NODE_LITE = gql`
  query {
    treeNodeLite {
      associate {
        legacyAssociateId
        associateSlugs {
          slug
        }
      }
      uplineAssociate {
        displayName
        firstName
        lastName
        associateId
      }
    }
  }
`

export const GET_USER_PROFILE = gql`
  query ($associateId: Int!) {
    associates(where: { associateId: { eq: $associateId } }) {
      displayName
    }
  }
`

export const GET_ASSOCIATE_BY_SLUG = gql`
  query ($slug: String!) {
    associateBySlug(slug: $slug) {
      firstName
      lastName
    }
  }
`

export const ADD_ASSOCIATE = gql`
  mutation AddAssociateV2(
    $enrollerSlug: String
    $dateBirth: DateTime
    $companyName: String
    $firstName: String
    $lastName: String
    $legalFirstName: String
    $legalLastName: String
    $displayName: String
    $emailAddress: String
    $primaryPhoneNumber: String
    $secondaryPhoneNumber: String
    $languageCode: String
    $address: InputAddressInput
    $shippingAddress: InputAddressInput
    $country: String!
    $receiveEmail: Boolean
    $receiveText: Boolean
  ) {
    addAssociateV2(
      input: {
        enrollerSlug: $enrollerSlug
        dateBirth: $dateBirth
        companyName: $companyName
        firstName: $firstName
        lastName: $lastName
        legalFirstName: $legalFirstName
        legalLastName: $legalLastName
        displayName: $displayName
        emailAddress: $emailAddress
        primaryPhoneNumber: $primaryPhoneNumber
        secondaryPhoneNumber: $secondaryPhoneNumber
        languageCode: $languageCode
        address: $address
        shippingAddress: $shippingAddress
        country: $country
        receiveEmail: $receiveEmail
        receiveText: $receiveText
      }
    ) {
      associateId
      firstName
      lastName
    }
  }
`

export const UPDATE_ASSOCIATE = gql`
  mutation UpdateAssociateV2(
    $associateId: Int!
    $areTermsAccepted: Boolean
    $dateBirth: DateTime
    $companyName: String
    $firstName: String
    $lastName: String
    $legalFirstName: String
    $legalLastName: String
    $displayName: String
    $emailAddress: String
    $primaryPhoneNumber: String
    $secondaryPhoneNumber: String
    $languageCode: String
    $address: InputAddressInput
    $shippingAddress: InputAddressInput
    $profileImageFileName: String
    $receiveEmail: Boolean
    $receiveText: Boolean
    $slug: String
    $includeBusinessPartner: Boolean
    $employerIdentificationNumber: String
    $send1099FormElectronically: Boolean
    $socialSecurityNumber: String
  ) {
    updateAssociateV2(
      input: {
        associateId: $associateId
        areTermsAccepted: $areTermsAccepted
        dateBirth: $dateBirth
        companyName: $companyName
        firstName: $firstName
        lastName: $lastName
        legalFirstName: $legalFirstName
        legalLastName: $legalLastName
        displayName: $displayName
        emailAddress: $emailAddress
        primaryPhoneNumber: $primaryPhoneNumber
        secondaryPhoneNumber: $secondaryPhoneNumber
        languageCode: $languageCode
        address: $address
        shippingAddress: $shippingAddress
        profileImageFileName: $profileImageFileName
        receiveEmail: $receiveEmail
        receiveText: $receiveText
        slug: $slug
        includeBusinessPartner: $includeBusinessPartner
        employerIdentificationNumber: $employerIdentificationNumber
        send1099FormElectronically: $send1099FormElectronically
        socialSecurityNumber: $socialSecurityNumber
      }
    ) {
      address {
        address1
        address2
        city
        state
        zip
      }
      addressId
      associateId
      associateStatus
      areTermsAccepted
      associateType
      displayName
      dS_Username
      dS_UsersRecordNumber
      emailAddress
      firstName
      lastName
      legacyAssociateId
      primaryPhoneNumber
      profileImageFileName
      receiveEmail
      receiveText
      shippingAddress {
        address1
        address2
        city
        state
        zip
      }
      shippingAddressId
      uniqueEmailAddress
    }
  }
`

export const IS_SLUG_AVAILABLE = gql`
  query ($slug: String!) {
    isSlugAvailable(slug: $slug) {
      isAvailable
      reason
      slug
    }
  }
`

export const SET_CUSTOMER_GROUP = gql`
  mutation SetCustomerGroup(
    $customerGroup: MagentoCustomerGroup!
    $magentoCustomerId: Int
  ) {
    setCustomerGroup(
      input: {
        customerGroup: $customerGroup
        magentoCustomerId: $magentoCustomerId
      }
    )
  }
`
