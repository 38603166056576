import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledModal,
  StyledLogo,
  StyledHeader,
  StyledModalContent,
  Container,
  StyledText,
  StyledTextSmall,
  StyledCheck,
} from './RenewalModalForAmb.styles'

const RenewalModalForAmb = ({ open, onClose }) => {
  return (
    <StyledModal
      closeIcon
      open={true}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <Container direction="column">
        <StyledLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        <StyledText>
          It's time to renew your Q Sciences Ambassador Subscription.
        </StyledText>
        <StyledHeader>Pick Your Perks!</StyledHeader>
      </Container>
      <StyledModalContent>
        <Container direction="column">
          <StyledTextSmall align="left">
            Renew today so you don't lose out on these benefits:
          </StyledTextSmall>
          <Container justify="flex-start">
            <StyledCheck />
            <StyledTextSmall align="left">
              Free Access to Ambassador Resources Q (ARQ).
            </StyledTextSmall>
          </Container>
          <Container justify="flex-start">
            <StyledCheck />
            <StyledTextSmall align="left">
              Free Access to MyQFit.
            </StyledTextSmall>
          </Container>
          <Container justify="flex-start">
            <StyledCheck />
            <StyledTextSmall align="left">
              Earning Q Rewards points to redeem for FREE products.
            </StyledTextSmall>
          </Container>
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}

RenewalModalForAmb.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RenewalModalForAmb
