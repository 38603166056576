import styled from 'styled-components'
import { Modal, Image } from 'semantic-ui-react'

export const Logo = styled(Image)`
  width: 200px;
  height: auto;
  margin: 2em 0;
`

export const StyledModal = styled(Modal)`
  &&& {
    display: flex;
    flex-flow: column nowrap;
    justify-content: ${props => props.justify || 'flex-start'};
    align-items: ${props => props.align || 'center'};
  }
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
`

export const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  max-width: ${props => props.maxWidth || '300px'};
  border: 1px solid #222;
  border-radius: 10px;
  margin: ${props => props.margin || '1rem 0 0.5rem 0'};
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  color: ${props => props.theme.colors.sapphireBlue};

  :hover {
    cursor: pointer;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  max-width: ${props => props.maxWidth || 'none'};
  padding: ${props => props.padding || '0.5rem'};
  align-self: center;
`

export const ErrorMsg = styled.span`
  color: ${props => props.theme.colors.carnelian};
  align-self: center;
  margin-bottom: 0;
`

export const FieldWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin-top: 1rem;
`
