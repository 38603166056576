import { useEffect, useState } from 'react'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
// Types
import { ProductType } from '../types/ProductTypes'

const useAddToCart = (productData: ProductType) => {
  const [product, setProduct] = useState({ ...productData })
  const [selectedOption, setSelectedOption] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(1)

  const { isAuthenticated, userType } = useAuthContext()

  const { cartId, buildCart, manageCart, showCartPopup } = useCartContext()

  useEffect(() => {
    setProduct(productData)
  }, [productData])

  const increment = () => setCount(count + 1)
  const decrement = () => (count > 1 ? setCount(count - 1) : setCount(1))

  const isBundleProduct = product?.items?.length > 0

  const handleLoadingAndPopup = () => {
    setIsLoading(false)
    showCartPopup(true)
  }

  const handleSetOption = (option: string) => setSelectedOption(option)

  const addToCart = async (count: number = 1) => {
    if (isAuthenticated && userType !== 'RETAIL') {
      setShowModal(true)
    }

    setIsLoading(true)

    if (isBundleProduct) {
      await manageCart
        .addBundleProductToCart(product, count, cartId, buildCart)
        .then(handleLoadingAndPopup)
    } else if (!!selectedOption) {
      await manageCart
        .addConfigurableProductToCart(
          { sku: product.sku, optionUid: selectedOption, name: product.name },
          count,
          cartId,
          buildCart
        )
        .then(handleLoadingAndPopup)
    } else {
      await manageCart
        .addItemToCart(product, count, cartId, buildCart)
        .then(handleLoadingAndPopup)
    }
  }

  return {
    count,
    isLoading,
    selectedOption,
    showModal,
    addToCart,
    decrement,
    increment,
    handleSetOption,
    setShowModal,
  }
}

export default useAddToCart
