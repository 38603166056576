export const COUNTRY_KEY = 'USER_COUNTRY'

/**
 * Gets the country of the user
 * Uses settings from localstorage first, then browser settings, then 'US'
 */
export const getUserCountry = (storage: Storage, locale: string) => {
  return storage.getItem(COUNTRY_KEY) ?? _getCountryFromLocale(locale)
}

/**
 * Saves country to localstorage
 * @param locale to get  (i.e. "en-US")
 */
export const saveUserCountry = (storage: Storage, locale: string) => {
  storage.setItem(COUNTRY_KEY, _getCountryFromLocale(locale))
}

/**
 * Returns country of the user from locale string formatted as 'en-US' / 'language-COUNTRY'
 * Also has to handle string with no country '(i.e. 'en')'
 * @param locale locale string provided from 'navigator.language' (i.e. 'en-US' or 'en')
 * @returns country (i.e. 'US' )
 */
export const _getCountryFromLocale = (locale: string) => {
  const country = locale.split('-')[1]
  return country ?? 'US'
}
